import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Popup from '../../components/reusable/Popups/Popup';
import Table from '../../components/reusable/Tables/Table';
import api, { WEB_URL } from '../../config/api';
import apiv2 from '../../config/apiv2';
import { TYPE_DATASOURCES } from '../../contants/datasource';
import { addStatusSyncOfDataSource } from '../../features/createBudget/createBudgetSlice';
import {
  setDataSource,
  setIsNewDataSource,
  setIsOpenNewDataSourceModal,
} from '../../features/dataSource/dataSourceSlice';
import { setMe } from '../../features/me/meSlice';
import { OAuthHandleFactory, getMinuteDiffWithNow } from '../../helpers';
import {
  successAddDataSource,
  successSourceDelete
} from '../../helpers/notyf';
import { useDebounce } from '../../hooks/useDebounce';
import css from './DataSources.module.css';

const DataSources = ({ type }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [toDisconnect, setToDisconnect] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const { updatedBudgetOrCampaignId } = useSelector(
    state => state.createBudget,
  );
  const { isOpenAddDataSourceModal } = useSelector(state => state.dataSource);

  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(search, 100);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const sources = ['google'];

  useEffect(() => {
    const isCreatedDataSourcePage = pathname.includes('/data-sources');
    const arrPathname = pathname.split('/');

    const { redirectUri, codeFrom, currentUrl } = getRedirectAndCodeValue(
      isCreatedDataSourcePage,
      arrPathname,
    );

    const objSearchParams = Object.fromEntries([...searchParams]);
    const code = objSearchParams?.code;
    const error = objSearchParams?.error;

    if ((error || !code) && !type) {
      navigate(currentUrl, { replace: true });
    }

    if (code) handleSendCode(codeFrom, code, redirectUri, currentUrl);
  }, []);

  useEffect(() => {
    Promise.all(
      sources.map(source => {
        if (source === 'google') {
          return api.GET_GOOGLE_DATA_SOURCES().then(res => {
            return res;
          });
        }
      }),
    ).then(res => {
      const concated = res.reduce(
        (previousValue, currentValue) => previousValue.concat(currentValue),
        [],
      );

      if (concated.length) {
        concated.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );

        const timeCreatedOfLatestDataSource = new Date(
          concated[0].created_at,
        ).getTime();
        const minuteDiff = getMinuteDiffWithNow(timeCreatedOfLatestDataSource);

        if (minuteDiff < 20) {
          dispatch(setIsNewDataSource(true));
        } else {
          dispatch(setIsNewDataSource(false));
        }
      }

      setData(concated);
      setSearchResult(concated);

      dispatch(setDataSource(concated));
    });
  }, [reload]);

  useEffect(() => {
    const result = data.filter(item =>
      item?.email.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );

    setSearchResult(result);
  }, [debouncedSearch, reload]);

  const getRedirectAndCodeValue = (isCreatedDataSourcePage, arrPathname) => {
    let redirectUri;
    let codeFrom;
    let currentUrl;

    if (isCreatedDataSourcePage) {
      redirectUri = `${WEB_URL}/data-sources`;
      codeFrom = arrPathname[2];
      currentUrl = `/data-sources`;
    } else {
      redirectUri = `${WEB_URL}/create-budget/campaigns`;
      codeFrom = arrPathname[3];
      currentUrl = `/create-budget/campaigns/${updatedBudgetOrCampaignId}`;
    }

    return {redirectUri, codeFrom, currentUrl}
  }

   const handleSendCode = (codeFrom, code, redirectUri, currentUrl) => {
     OAuthHandleFactory[codeFrom]
       .sendCode(code, `${redirectUri}/${codeFrom}`)
       .then(res => {
         if (res.data) {
           const { id, activity_sync_history_log } = res.data;
           const isNewDataSource = id && activity_sync_history_log
           
           if (isNewDataSource) {
             dispatch(
               addStatusSyncOfDataSource({
                 sourceId: id,
                 activitySyncHistoryLog: activity_sync_history_log,
               }),
             );
           }
           const type = TYPE_DATASOURCES[codeFrom].name.split(' ')[0];

           apiv2.GET_ME().then(res => dispatch(setMe(res.data)));
           handleAfterAddDataSourceSuccess(type, currentUrl);
         }
       });
   };

  const handleAfterAddDataSourceSuccess = (type, currentUrl) => {
    navigate(currentUrl, { replace: true });
    setReload(!reload);
    successAddDataSource(type)
  }

  const handleAddSource = () => {
    dispatch(setIsOpenNewDataSourceModal(true))
  };

  const handleDisconnect = (id, e) => {
    setToDisconnect(id);
    setIsDisconnect(true);
  };

  const handleClose = () => {
    dispatch(setIsOpenNewDataSourceModal(false));
    setIsDisconnect(false);
    setToDisconnect(null);
  };

  const handleAuth = (type, e) => {
    const redirectUri = getBaseRedirectUri();
    
    OAuthHandleFactory[type].getOAuthUrl(`${redirectUri}/${type.toLowerCase()}`).then(res => {
      if (res.status === 200) {
        window.location.replace(res.data.url);
      }
    })
  };

  const getBaseRedirectUri = () => {
    if (pathname.includes('/create-budget/campaigns')) {
      return `${WEB_URL}/create-budget/campaigns`;
    } else if (pathname === '/data-sources') {
      return `${WEB_URL}/data-sources`;
    }
  };

  const handleDisconnectConfirm = () => {
    api.DELETE_GOOGLE_DATA_SOURCE(toDisconnect).then(res => {
      if (!res) {
        setReload(!reload);
        successSourceDelete();
      }
    });
    setIsDisconnect(false);
    setToDisconnect(null);
  };

  const handleSearch = e => setSearch(e.target.value);

  return type === 'budget' ? (
    <>
      {isOpenAddDataSourceModal && (
        <Popup type="addSource" onClose={handleClose} onClick={handleAuth} />
      )}
      <Table
        data={searchResult}
        search={search}
        handleSearch={handleSearch}
        handleAddSource={handleAddSource}
        handleAuth={handleAuth}
        type="budgetData"
      />
    </>
  ) : (
    <div className={css.main}>
      {isOpenAddDataSourceModal && (
        <Popup type="addSource" onClose={handleClose} onClick={handleAuth} />
      )}
      {isDisconnect && (
        <Popup
          type="disconnect"
          onClose={handleClose}
          onClick={handleDisconnectConfirm}
        />
      )}
      <div className={css.wrapper}>
        <Table
          data={data}
          search={search}
          handleSearch={handleSearch}
          handleAddSource={handleAddSource}
          handleDisconnect={handleDisconnect}
          handleAuth={handleAuth}
          type="data"
        />
      </div>
    </div>
  );
};

export default DataSources;

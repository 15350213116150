import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/reusable/Button/Button';
import AutomatedRuleTable from '../../../components/reusable/Tables/AutomatedRuleTable';
import { RESOURCES } from '../../../contants/resources';
import { setRuleManagerState } from '../../../features/userSettings/userSettingsSlice';
import { useTable } from '../../../hooks/useTable';
import css from './../UserSettings.module.css';
import AutomatedRuleManager from './components/AutomatedRuleManager';
import style from './styles.module.css';

const AutomatedRules = () => {
  const [mode, setMode] = useState(null);

  const { ruleManagerState } = useSelector(state => state.userSettings);
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const handleClickAddNewRule = () => {
    setMode('create');
    dispatch(setRuleManagerState(true));
  };

  useEffect(() => {
    const idRuleIsValid = id && !isNaN(id);

    if (idRuleIsValid) {
      dispatch(setRuleManagerState(true));
      setMode('edit');
    } else {
      dispatch(setRuleManagerState(false));
      navigate('/settings/automated-rules');
    }
  }, [id]);

  return (
    <div className={css.main}>
      <div className={css.wrapper}>
        {ruleManagerState ? (
          <AutomatedRuleManager mode={mode} />
        ) : (
          <>
            <Button
              styled="addClient"
              title="+ Add New Rules"
              onClick={handleClickAddNewRule}
            />

            <div className={style.table}>
              <AutomatedRuleTable />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AutomatedRules;

import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../config/api';
import { MESSAGE_ERROR } from '../../contants/messageError';
import {
  addFormData,
  changeChoosenAccounts,
  changeChoosenCampaigns,
  changeChoosenSources,
  changeTab,
  setAmountError,
  setEndDateError,
  setIsActive,
  setNameError,
  setStartDateError,
  setTypeError,
  setUniqueNameError,
} from '../../features/createBudget/createBudgetSlice';
import { error } from '../../helpers/notyf';
import Button from '../reusable/Button/Button';
import Tabs from '../reusable/Tabs/Tabs';
import css from './SetBudget.module.css';
import SetCustomBudget from './SetCustomBudget';
import SetMonthlyWeeklyBudget from './SetMonthlyWeeklyBudget';

const SetBudget = () => {
  const { isActive, tab, formData, clients } = useSelector(
    state => state.createBudget,
  );

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const typeOptions = [
    { value: 1, label: 'Recurring' },
    { value: 2, label: 'One-Time' },
  ];

  const monthlyOptions = [
    { value: '1', label: '1st' },
    { value: '2', label: '2nd' },
    { value: '3', label: '3rd' },
    { value: '4', label: '4th' },
    { value: '5', label: '5th' },
    { value: '6', label: '6th' },
    { value: '7', label: '7th' },
    { value: '8', label: '8th' },
    { value: '9', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '28', label: '28th' },
    { value: '29', label: '29th' },
    { value: '30', label: '30th' },
    { value: '31', label: '31st' },
  ];

  const weeklyOptions = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
  ];

  useEffect(() => {
    if (params.id && !isNaN(params.id) && clients.length > 0) {
      api.GET_BUDGET(params.id).then(data => {
        let dataToSet = {
          ...formData,
          id: +data.id,
          amount: +data.amount,
          name: data.name,
          google_budget: data.google_budget,
          client: clients.find(client => +client.value === +data.client.id),
          type: data.type,
        };
        if (data.month_start_day) {
          dataToSet.month_start_day = monthlyOptions.find(
            opt => +opt.value === +data.month_start_day,
          );
        }
        if (data.week_start_day) {
          dataToSet.week_start_day = weeklyOptions.find(
            opt => +opt.value === +data.week_start_day,
          );
          dispatch(changeTab('weekly'));
        }
        if (data.start_date && data.end_date) {
          dataToSet.renew_type = typeOptions.find(
            opt => opt.label === data.renew_type,
          );
          dataToSet.start_date = data.start_date;
          dataToSet.end_date = data.end_date;
          dispatch(changeTab('custom'));
        }
        dispatch(addFormData({ ...dataToSet }));

        if (data.campaigns.length) {
          dispatch(changeChoosenCampaigns(data.campaigns));
          dispatch(changeChoosenAccounts([data.campaigns[0]?.account]));
          dispatch(changeChoosenSources([data.campaigns[0]?.account?.source]));
        }
      });
    }
  }, [params.id, clients]);

  const handleTabChange = (tab, e) => dispatch(changeTab(tab));

  const handleContinue = () => {
    const { amount, name, start_date, end_date, type } = formData;
    if ((tab === 'monthly' || tab === 'weekly')){
      let isValid = validateAmount(amount) && validateName(name);

      if(!isValid) return
    }
    if (
      tab === 'custom'
    ) {
      let isValid = validateAmount(amount) && validateName(name) && start_date && end_date && type;

      if (!start_date) dispatch(setStartDateError(true));
      if (!end_date) dispatch(setEndDateError(true));
      if (!type) dispatch(setTypeError(true));

      if(!isValid) return
    }

    const daysDifferent = moment(end_date).diff(moment(start_date), 'days');
    if (daysDifferent < 2) {
      dispatch(setStartDateError(true));
      dispatch(setEndDateError(true));
      return;
    } 

    const isModeEditBudget = params.id && !isNaN(params.id);
    const bodyCheckBudgetName = {
      budgetName: formData.name.trim(),
      ...(isModeEditBudget && { budgetId: params.id }),
    };

    api
      .CHECK_BUDGET_NAME(bodyCheckBudgetName)
      .then(res => {
        if (isModeEditBudget) {
          dispatch(setIsActive({ ...isActive, step3: true }));
          navigate(`/create-budget/campaigns/${params.id}`);
        } else {
          dispatch(setIsActive({ ...isActive, step3: true }));
          navigate('/create-budget/campaigns');
        }
      })
      .catch(err => {
        const { response } = err;
        const message = response.data.name[0];

        if (
          message === MESSAGE_ERROR.DUPLICATE_NAME_CLIENT &&
          response.status === 400
        ) {
          dispatch(setUniqueNameError(true));
        } else {
          error(err);
        }
      });
  };

  const validateAmount = (amount) => {
    if (!amount) {
      dispatch(setAmountError("Please provide a budget amount"));
      return false;
    } else if (amount.toString().length > 8) {
      dispatch(setAmountError("Please provide a budget amount less than 8 digits"));
      return false;
    }
    return true;
  };

  const validateName = (name) => {
    if (!name) {
      dispatch(setNameError(true));
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={css.wrapper}>
        <h1 className={css.title}>Set Budget</h1>
        <p className={css.text}>
          Amount and cycle can be adjusted at any time. Standard budgets will
          automatically renew, choose a custom budget if you would like to set a
          specific start date, end date or recurring cycle.
        </p>
        <Tabs tab={tab} handleTabChange={handleTabChange} type="budget" />
        <div className={css.container}>
          {(tab === 'monthly' || tab === 'weekly') && (
            <SetMonthlyWeeklyBudget
              type={tab}
              monthlyOptions={monthlyOptions}
              weeklyOptions={weeklyOptions}
            />
          )}
          {tab === 'custom' && <SetCustomBudget options={typeOptions} />}
        </div>
      </div>
      <div className={css.btn}>
        <Button styled="addClient" title="Continue" onClick={handleContinue} />
      </div>
    </>
  );
};

export default SetBudget;

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import close from '../../../assets/close.png';
import apiv2 from '../../../config/apiv2';
import { setCardData } from '../../../features/userSettings/userSettingsSlice';
import { errorBilling, successBilling } from '../../../helpers/notyf';
import Button from '../Button/Button';
import Input from '../Input/Input';
import css from '../Modals/Modals.module.css';
import cssPopUp from './Popups.module.css';
import './popup.css';
import { AxiosError } from 'axios';
import { setTriggerGetMe } from '../../../features/me/meSlice';

const currencyOptions = [
  { value: 1, label: 'USD' },
  { value: 2, label: 'EUR' },
  { value: 3, label: 'GBP' },
  { value: 4, label: 'AUD' },
  { value: 5, label: 'CAD' },
];

const inputStyle = {
  base: {
    fontSize: '14.4px',
    color: '#726f95',
    fontWeight: 500,
    '::placeholder': {
      color: '#726f95',
    },
  },
};

const PaymentPopup = ({ type, onClose, setInvoices }) => {
  const [currency, setCurrency] = useState('');
  const [currencyErr, setCurrencyErr] = useState(false);
  const [postCode, setPostCode] = useState('');
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const needRefresh = useSelector((state) => state.me.triggerGetMe)

  const [errorCardElement, setErrorCardElement] = useReducer(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
    }),
    {
      cardNumber: null,
      expiryDate: null,
      securityCode: null,
    },
  );

  const dispatch = useDispatch();
  const cardData = useSelector(state => state.userSettings.cardData);

  const stripe = useStripe();
  const elements = useElements();

  const actionPaymentMethod = `${
    cardData?.stripe_payment_method_id ? 'Update' : 'Add'
  } Payment Method`;

  const isOnlyExpiryDateError =
    errorCardElement.expiryDate && !errorCardElement.securityCode;
  const isOnlySecurityCodeError =
    !errorCardElement.expiryDate && errorCardElement.securityCode;

  useEffect(() => {
    if (postCode) {
      setPostCodeErr(false);
    }
  }, [postCode]);

  useEffect(() => {
    if (cardData && elements) {
      setPostCode(cardData?.zip_code);
      const valueCurrency = currencyOptions.find(
        currency => currency.label === cardData?.currency,
      );
      setCurrency(valueCurrency);

      const cardNumber = elements.getElement(CardNumberElement);
      const cardExpiry = elements.getElement(CardExpiryElement);
      const cardCvc = elements.getElement(CardCvcElement);

      const maskedCardNumber = cardData.stripe_card_last4
        ? `**** **** **** ${cardData.stripe_card_last4}`
        : '1234 1234 1234 1234';
      const CardExpiryPlaceholder = cardData.stripe_card_expire_month
        ? `${cardData.stripe_card_expire_month}/${(
            cardData.stripe_card_expire_year + ''
          ).slice(-2)}`
        : 'MM/YY';
      const maskedCardCvc =
        cardData.stripe_card_last4 &&
        cardData.stripe_card_expire_month &&
        cardData?.stripe_payment_method_id
          ? '***'
          : 'CVC';

      cardNumber?.update({
        placeholder: maskedCardNumber,
      });
      cardExpiry?.update({
        placeholder: CardExpiryPlaceholder,
      });
      cardCvc?.update({
        placeholder: maskedCardCvc,
      });
    }
  }, [cardData, elements]);

  const handleInputChange = event => {
    let { value } = event.target;
    value = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Remove non-digit characters
    setPostCode(value);
  };

  // Handle on-change validation errors from the CardElement.
  const handleCardElementChange = event => {
    const { elementType, error } = event;

    if (error) {
      const { message } = error;
      const messageWithoutDot = message.replace(/\./g, '');

      setErrorCardElement({
        ...(elementType === 'cardNumber'
          ? { cardNumber: messageWithoutDot }
          : {}),
        ...(elementType === 'cardExpiry'
          ? { expiryDate: messageWithoutDot }
          : {}),
        ...(elementType === 'cardCvc'
          ? { securityCode: messageWithoutDot }
          : {}),
      });
    } else {
      setErrorCardElement({
        ...(elementType === 'cardNumber' ? { cardNumber: null } : {}),
        ...(elementType === 'cardExpiry' ? { expiryDate: null } : {}),
        ...(elementType === 'cardCvc' ? { securityCode: null } : {}),
      });
    }
  };

  // DEVELOPING
  // Handle submit save payment method
  const handleSubmit = async event => {
    event.preventDefault();

    const cardNumber = elements.getElement(CardNumberElement);
    const isValid = validatePaymentMethodForm();

    if (isValid) {
      try {
        const subscriptionRes = await savePaymentMethod(cardNumber);
        const subscription = subscriptionRes.data.subscription;

        // for setup intent after create subscription
        if (subscription?.pending_setup_intent?.status === 'requires_action') {
          await handleCardSetUp(subscription?.pending_setup_intent);
        }

        // for confirm card payment for payment intent to pay
        if (subscriptionRes.data?.payment_state) {
          await handleCardPayment(subscriptionRes.data?.payment_state);
        }

        apiv2
          .COMPANIES_BILLING_GET()
          .then(res => dispatch(setCardData(res.data)));
        
        dispatch(setTriggerGetMe(!needRefresh))
        onClose();

        successBilling('Card saved successfully');
      } catch (error) {
        let message =
          error?.message || 'Something wrong while add payment method';
        if (error instanceof AxiosError) {
          message = error?.response?.data && error?.response?.data.error;
        }

        errorBilling(message);
      } finally {
        setLoading(false);
      }
    }
  };

  const savePaymentMethod = async cardNumber => {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        address: {
          postal_code: postCode,
        },
      },
    });

    if (error) throw new Error(error);

    setLoading(true);
    const data = {
      stripe_payment_method_id: paymentMethod.id,
    };

    const subscriptionRes = await apiv2.COMPANIES_BILLING_POST(data);
    return subscriptionRes;
  };

  const handleCardSetUp = async pending_setup_intent => {
    const { client_secret, payment_method } = pending_setup_intent;
    const { error } = await stripe.confirmCardSetup(client_secret, {
      payment_method: payment_method,
    });

    if (error) {
      throw new Error(error.message);
    }
  };

  const handleCardPayment = async payment_state => {
    let message = '';
    if (payment_state?.status === 'succeeded') {
      message = 'Pay successfully';
    } else if (payment_state?.status === 'requires_action') {
      const client_secret = payment_state?.client_secret;

      const { error } = await stripe.confirmCardPayment(client_secret);
      if (error) {
        throw new Error(error.message);
      }
      message = 'Pay successfully';
    }

    if (message) {
      successBilling(message);
      apiv2.INVOICES().then(res => setInvoices(res.data));
      
      const data = {
        stripe_payment_method_id: payment_state?.payment_method,
        only_set_payment_method: true,
      };

      await apiv2.COMPANIES_BILLING_POST(data);
    }
  };

  // DEVELOPING
  const validatePaymentMethodForm = () => {
    let isValid = true;
    const trimmedPostCode = postCode ? postCode.trim() : '';

    if (!trimmedPostCode) {
      isValid = false;
      setPostCodeErr(true);
    } else {
      setPostCodeErr(false);
    }
    return isValid && validateStripeElement();
  };

  const validateStripeElement = () => {
    let isValid = true;
    const stripeElements = document.querySelectorAll('.StripeElement');

    stripeElements.forEach(element => {
      const isInvalid =
        !element.classList.contains('StripeElement--invalid') &&
        !element.classList.contains('StripeElement--complete');
      if (isInvalid) {
        isValid = false;
        element.classList.add('StripeElement--invalid');

        setErrorCardElement({
          ...(element.classList.contains('cardNumber')
            ? { cardNumber: 'Your card number is incomplete' }
            : {}),
          ...(element.classList.contains('cardCvc')
            ? { securityCode: "Your card's security code is incomplete" }
            : {}),
          ...(element.classList.contains('cardExpiry')
            ? { expiryDate: "Your card's expiration date is incomplete" }
            : {}),
        });
      }
    });

    return isValid;
  };

  return (
    <div>
      {loading && (
        <div className={cssPopUp.paymentFormLoading}>
          <div className={css.loader}></div>
        </div>
      )}
      <div className={css.wrap}>
        <h1 className={css.titleForPayment}>{actionPaymentMethod}</h1>
        <img src={close} className={css.closeBtn} onClick={onClose} />
      </div>
      <form className={css.form} onSubmit={handleSubmit}>
        <p className={css.formTitle}>Card Number</p>
        <div className={css.errorText}>{errorCardElement.cardNumber}</div>
        <CardNumberElement
          options={{
            style: inputStyle,
            classes: {
              base: 'StripeElement cardNumber',
            },
          }}
          onChange={handleCardElementChange}
        />

        <div className={css.wrapForFields}>
          <div className={css.wrapForField}>
            <p
              className={css.formTitle}
              style={{ marginBottom: isOnlySecurityCodeError ? '3.2rem' : '' }}
            >
              Expiry Date
            </p>
            <div className={css.errorText}>{errorCardElement.expiryDate}</div>
            <CardExpiryElement
              options={{
                style: inputStyle,
                classes: {
                  base: 'StripeElement cardExpiry',
                },
              }}
              onChange={handleCardElementChange}
            />
          </div>
          <div className={css.wrapForField}>
            <p
              className={css.formTitle}
              style={{ marginBottom: isOnlyExpiryDateError ? '3.2rem' : '' }}
            >
              Security Code
            </p>
            <div className={css.errorText}>{errorCardElement.securityCode}</div>
            <CardCvcElement
              options={{
                style: inputStyle,
                classes: {
                  base: 'StripeElement cardCvc',
                },
              }}
              onChange={handleCardElementChange}
            />
          </div>
        </div>
        <p className={css.formTitle}>Billing Zip / Postcode</p>
        {postCodeErr && (
          <div className={css.errorText}>This field is required</div>
        )}
        <Input
          name="name"
          type="text"
          placeholder="Enter your zip code or postcode"
          value={postCode}
          onChange={handleInputChange}
          className="placeholder"
        />

        {type === 'addPayment' && (
          <p className={css.formInfo}>
            Recurring subscriptions are billed in USD.
          </p>
        )}
        <div className={css.btnsWrapper}>
          <Button
            styled="cancel"
            title="Cancel"
            onClick={onClose}
            style={{ width: '47%' }}
          />
          <Button
            type="submit"
            styled="submit"
            title={actionPaymentMethod}
            style={{ width: '47%' }}
            // onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PaymentPopup;

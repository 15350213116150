import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../../assets/cross.svg';
import plus from '../../../assets/plus.svg';
import updateIcon from '../../../assets/update.png';
import apiv2 from '../../../config/apiv2';
import { BACKOFF_FACTOR, MAX_RETRIES } from '../../../contants';
import { STATUS } from '../../../contants/datasource';
import {
  addStatusSyncOfDataSource,
  resetTimeOutId,
  setIsProcessSyncDataSource,
  setTimeOutIds,
  updateStatusSyncOfDataSource,
} from '../../../features/createBudget/createBudgetSlice';
import { successNotif } from '../../../helpers/notyf';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import Input from '../Input/Input';
import DashboardTable from './DashboardTable';
import DataSourcesTable from './DataSourcesTable';
import InvoicesTable from './InvoicesTable';
import css from './Tables.module.css';

const formater = (type, title, btn) => {
  if (title) {
    if (type === 'data') return 'Data Sources';
    // if (type === 'accounts') return 'Accounts';
    // if (type === 'campaigns') return 'Campaigns';
  } else if (btn) {
    if (type === 'budgetData') return 'Add';
    if (type === 'data') return 'Add Data Sources';
  }
};

const Table = ({
  data,
  search,
  filter,
  setFilter,
  handleSearch,
  handleAddSource,
  handleDisconnect,
  handleSelectCampaigns,
  handleAuth,
  isLoading,
  type,
}) => {
  const [historyData, setHistoryData] = useState('');

  const dispatch = useDispatch();

  const {
    choosenSources,
    accounts,
    campaigns,
    statusSyncOfDataSource,
    isProcessSyncDataSource,
    timeOutIds,
  } = useSelector(state => state.createBudget);
  const choosenAccounts = useSelector(
    state => state.createBudget.choosenAccounts,
  );

  useEffect(() => {
    fetchLastSyncTimeForData();

    return () => {
      dispatch(setIsProcessSyncDataSource(false));
      clearTimeOutIds();
    };
  }, [type, choosenAccounts]);

  const fetchLastSyncTimeForData = () => {
    if (type === 'budgetData') {
      apiv2
        .GET_HISTORY_SOURCES()
        .then(res => setHistoryData(res.data.last_sync_datetime))
        .catch(err => err);
    }
    if (type === 'accounts') {
      const sourceId = choosenSources[0]?.id;
      handleDataSourceSelected(sourceId);
    }
    if (type === 'campaigns') {
      if (choosenAccounts[0]?.id) {
        fetchLastSyncTimeForAccount(
          choosenAccounts[0]?.source?.id,
          choosenAccounts[0]?.id,
        );
      }
    }
  };

  const handleDataSourceSelected = sourceId => {
    if (sourceId) {
      const statusSyncSource = statusSyncOfDataSource.find(
        statusSync => statusSync?.sourceId === sourceId,
      );

      if (statusSyncSource) {
        if (
          statusSyncSource?.activitySyncHistoryLog?.sync_accounts_completed_at
        ) {
          fetchLastSyncTimeForDataSource(sourceId);
        } else {
          handleDataSourceSyncStatus(
            sourceId,
            statusSyncSource?.activitySyncHistoryLog?.id,
          );
        }
      } else {
        fetchLastSyncTimeForDataSource(sourceId);
      }
    }
  };

  const handleDataSourceSyncStatus = (sourceId, activitySyncHistoryLogId) => {
    checkSyncStatusOfDataSourceWithRetry(sourceId, activitySyncHistoryLogId);
  };

  const checkSyncStatusOfDataSourceWithRetry = (
    sourceId,
    activitySyncHistoryLogId,
    retry = 0,
  ) => {
    if (retry >= MAX_RETRIES) {
      dispatch(setIsProcessSyncDataSource(false));
      return;
    }

    apiv2.GET_ACTIVITY_SYNC_HISTORY_LOG(activitySyncHistoryLogId).then(res => {
      const { sync_accounts_completed_at } = res.data;
      if (sync_accounts_completed_at) {
        handleWhenCompleteSyncAccountForDataSource(res.data, sourceId);
      } else {
        handleWhenSyncingAccountForDataSource(
          sourceId,
          activitySyncHistoryLogId,
          retry + 1,
        );
      }
    });
  };

  const handleWhenCompleteSyncAccountForDataSource = (
    activitySyncHistoryLog,
    sourceId,
  ) => {
    dispatch(setIsProcessSyncDataSource(false));
    dispatch(
      updateStatusSyncOfDataSource({
        sourceId,
        activitySyncHistoryLog,
      }),
    );
    fetchLastSyncTimeForDataSource(sourceId);
    clearTimeOutIds();
  };

  const handleWhenSyncingAccountForDataSource = (
    sourceId,
    activitySyncHistoryLogId,
    times,
  ) => {
    if (!isProcessSyncDataSource) {
      dispatch(setIsProcessSyncDataSource(true));
    }

    const timeOutId = setTimeout(() => {
      console.log(
        `Retry ${times} times of ${sourceId} at ${new Date()} with ${timeOutId}`,
      );
      checkSyncStatusOfDataSourceWithRetry(
        sourceId,
        activitySyncHistoryLogId,
        times,
      );
    }, (times - 1) * BACKOFF_FACTOR * 1000);

    dispatch(setTimeOutIds(timeOutId));
  };

  const fetchLastSyncTimeForDataSource = sourceId => {
    apiv2
      .GET_HISTORY_ACCOUNTS(sourceId)
      .then(res => {
        setHistoryData(res.data.last_sync_datetime);
      })
      .catch(err => console.log(err));
  };

  const haveDisconnectDataSource =
    type === 'data' &&
    data?.find(dataSource => dataSource.status !== STATUS.ACTIVE);

  const isShowSyncButton =
    (type === 'accounts' && choosenSources[0]?.id) ||
    (type === 'campaigns' && choosenAccounts[0]?.id);

  const isSourceSelectedAndNotEmpty =
    type === 'accounts' && choosenSources[0]?.id;
  const isCampaignsSelectedAndNotEmpty =
    type === 'campaigns' && choosenAccounts[0]?.id && campaigns.length > 0;
  const isShowLastUpdated =
    isSourceSelectedAndNotEmpty || isCampaignsSelectedAndNotEmpty;

  const handleClearFilters = () => {
    setFilter('');
  };

  const updateFunc = () => {
    if (type === 'accounts') {
      apiv2.GET_SOURCES_ACCOUNT(choosenSources[0].id).then(res => {
        const statusSyncSource = statusSyncOfDataSource.find(
          statusSync => statusSync?.sourceId === choosenSources[0].id,
        );

        const payloadActivitySyncHistoryLog = {
          sourceId: choosenSources[0].id,
          activitySyncHistoryLog: res?.data,
        };

        dispatch(
          statusSyncSource
            ? updateStatusSyncOfDataSource(payloadActivitySyncHistoryLog)
            : addStatusSyncOfDataSource(payloadActivitySyncHistoryLog),
        );

        handleDataSourceSyncStatus(choosenSources[0].id, res?.data.id);
        successNotif();
        // apiv2
        //   .GET_HISTORY_ACCOUNTS(choosenSources[0].id)
        //   .then(res => setHistoryData(res.data.last_sync_datetime));
      });
    }
    if (type === 'campaigns') {
      apiv2
        .GET_SOURCES_COMPAIGNS(
          choosenAccounts[0].source.id,
          choosenAccounts[0].id,
        )
        .then(res => {
          successNotif();
          handleAccountSyncStatus(
            choosenAccounts[0].source.id,
            choosenAccounts[0].id,
            res?.data.id,
          );
        });
    }
  };

  const handleAccountSyncStatus = (
    sourceId,
    accountId,
    activitySyncHistoryLogId,
  ) => {
    checkSynStatusOfAccountWithRetry(
      sourceId,
      accountId,
      activitySyncHistoryLogId,
    );
  };

  const checkSynStatusOfAccountWithRetry = (
    sourceId,
    accountId,
    activitySyncHistoryLogId,
    retry = 0,
  ) => {
    if (retry >= 5) {
      dispatch(setIsProcessSyncDataSource(false));
      return;
    }

    apiv2.GET_ACTIVITY_SYNC_HISTORY_LOG(activitySyncHistoryLogId).then(res => {
      const { sync_campaigns_completed_at } = res.data;
      if (sync_campaigns_completed_at) {
        handleWhenCompleteSyncCampaignsForAccount(sourceId, accountId);
      } else {
        handleWhenSyncingCampaignForAccount(
          sourceId,
          accountId,
          activitySyncHistoryLogId,
          retry + 1,
        );
      }
    });
  };

  const handleWhenSyncingCampaignForAccount = (
    sourceId,
    accountId,
    activitySyncHistoryLogId,
    times,
  ) => {
    if (!isProcessSyncDataSource) {
      dispatch(setIsProcessSyncDataSource(true));
    }

    const timeOutId = setTimeout(() => {
      console.log(
        `Retry ${times} times of ${sourceId} at ${new Date()} with ${timeOutId}`,
      );
      checkSynStatusOfAccountWithRetry(
        sourceId,
        accountId,
        activitySyncHistoryLogId,
        times,
      );
    }, (times - 1) * BACKOFF_FACTOR * 1000);

    dispatch(setTimeOutIds(timeOutId));
  };

  const handleWhenCompleteSyncCampaignsForAccount = (sourceId, accountId) => {
    dispatch(setIsProcessSyncDataSource(false));
    fetchLastSyncTimeForAccount(sourceId, accountId);
    clearTimeOutIds();
  };

  const fetchLastSyncTimeForAccount = (sourceId, accountId) => {
    apiv2
      .GET_HISTORY_COMPAIGNS(sourceId, accountId)
      .then(res => setHistoryData(res.data.last_sync_datetime));
  };

  const renderLastSync = () => {
    if (isProcessSyncDataSource) {
      return 'In progress';
    } else if (historyData) {
      return moment(historyData).fromNow();
    }
  };

  const clearTimeOutIds = () => {
    timeOutIds.forEach(timeOutId => clearTimeout(timeOutId));
    dispatch(resetTimeOutId());
  };

  return (
    <>
      {type !== 'confirmBudget' && (
        <header
          className={`${css.header} ${
            type === 'data' ||
            type === 'budgetData' ||
            type === 'accounts' ||
            type === 'campaigns'
              ? ''
              : css.mb0
          } ${
            type === 'campaigns' || type === 'accounts' || type === 'budgetData'
              ? css.sticky
              : ''
          }`}
        >
          {(type === 'data' ||
            type === 'budgetData' ||
            type === 'accounts' ||
            type === 'campaigns') && (
            <div className={css.container}>
              <div className={css.headerCampaigns}>
                <h1 className={css.title}>{formater(type, true)}</h1>
                {type === 'data' && haveDisconnectDataSource && (
                  <div className={css.wrapperAlert}>
                    <Alert
                      type="warning"
                      message="Access token has expired. Resync the data source to regain access."
                    />
                  </div>
                )}

                {type !== 'data' && (
                  <Input
                    styled="search"
                    type="text"
                    placeholder="Search..."
                    style={{ width: 240 }}
                    value={search}
                    onChange={handleSearch}
                  />
                )}
              </div>
              {type === 'campaigns' && filter && (
                <div className={css.selectedFilter}>
                  <span className={css.selectedText}>{filter}</span>
                  <img src={cross} onClick={handleClearFilters} />
                </div>
              )}
            </div>
          )}
          {isShowSyncButton && (
            <>
              <button
                className={`${css.updateWrapper} ${
                  isProcessSyncDataSource ? css.disabled : ''
                }`}
                onClick={updateFunc}
                disabled={isProcessSyncDataSource}
              >
                <img src={updateIcon} className={css.icon_update} />
                <span className={css.updateText}>Update</span>
              </button>
            </>
          )}
          {(type === 'data' || type === 'budgetData') && (
            <Button
              styled="addSource"
              onClick={handleAddSource}
              style={{
                width: 200,
              }}
            >
              <div className={css.btnWrapper}>
                <img alt="add" src={plus} className={css.addImg} />
                <span className={css.addText}>
                  {formater(type, null, true)}
                </span>
              </div>
            </Button>
          )}

          {type === 'campaigns' && (
            <Button styled="addSource" onClick={handleSelectCampaigns}>
              <div className={css.btnWrapper}>
                <span className={css.addText}>Add</span>
              </div>
            </Button>
          )}
        </header>
      )}
      {type === 'data' && (
        <DataSourcesTable
          data={data}
          handleDisconnect={handleDisconnect}
          handleAuth={handleAuth}
          type={type}
        />
      )}
      {isShowLastUpdated && (
        <div className={css.lastUpdate}>
          <div>Last sync:</div>
          {renderLastSync()}
        </div>
      )}
      {type === 'budgetData' && (
        <DataSourcesTable
          data={data}
          handleAuth={handleAuth}
          type={type}
          onChangeDataSource={clearTimeOutIds}
        />
      )}
      {(type === 'accounts' || type === 'campaigns') && (
        <>
          <DataSourcesTable
            data={isProcessSyncDataSource ? [] : data}
            type={type}
            isLoading={isProcessSyncDataSource || isLoading}
            filter={filter}
            setFilter={setFilter}
            clearSearchData={() => handleSearch('')}
          />
        </>
      )}
      {type === 'confirmBudget' && <DataSourcesTable type={type} />}
      {(type === 'clients-budget-pacing' ||
        type === 'clients-performance' ||
        type === 'budgets-budget-pacing' ||
        type === 'budgets-performance' ||
        type === 'campaigns-budget-pacing' ||
        type === 'campaigns-performance') && <DashboardTable type={type} />}
      {type === 'invoices' && (
        <InvoicesTable data={data} isLoading={isLoading} />
      )}
    </>
  );
};

export default Table;

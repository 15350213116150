import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BillingNitif } from './components/BillingNotif/BillingNotif';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import apiv2 from './config/apiv2';
import { PROTECTED_ROUTES } from './contants';
import { PERMISSIONS } from './contants/permissions';
import { setIsOpenNewDataSourceModal } from './features/dataSource/dataSourceSlice';
import { setMe } from './features/me/meSlice';
import { AppRoutes } from './routes/appRoutes';

const routeWithoutHeader = [
  '/signup',
  '/signupv2',
  '/login',
  '/reset-password',
  '/confirm-invitation/',
  '/create-account',
];

const App = () => {
  const [validAccess, setValidAccess] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const canShowHeaderAndSidebar = PROTECTED_ROUTES.find(route =>
    location.pathname.includes(route),
  );
  const isPossibleRoute =
    location.pathname && !routeWithoutHeader.includes(location.pathname);
  const getIsLoadMain = !!useSelector(state => state.me?.id);
  const isLoadMainAndPossibleRoute = isPossibleRoute && getIsLoadMain;
  const isNeedShowNotifDataSource =
    !useSelector(state => state.me?.has_data_sources) &&
    isLoadMainAndPossibleRoute;
  const isNeedShowNotifClient =
    !useSelector(state => state.me?.has_clients) && isLoadMainAndPossibleRoute;
  const isNeedShowNotifBudget =
    !useSelector(state => state.me?.has_budgets) && isLoadMainAndPossibleRoute;
  const needRefresh = useSelector(state => state.me.triggerGetMe);

  const [showNotif, setShowNotif] = useState(false);
  const [showNotifAccount, setShowNotifAccount] = useState(false);
  const [billingNotification, setBillingNotification] = useState({
    boldText: '',
    text: '',
  });
  const [accountNotification, setAccountNotification] = useState({
    boldText: '',
    text: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!location) return;
    if (location.pathname === '/login') {
      setShowNotif(false);
      setBillingNotification({
        boldText: '',
        text: '',
      });
    }
  }, [location]);

  function linkCreator() {
    if (isNeedShowNotifDataSource) {
      navigate('/data-sources');
      dispatch(setIsOpenNewDataSourceModal(true));
      return;
    } else if (isNeedShowNotifClient && !isNeedShowNotifDataSource) {
      navigate('/create-client');
      return;
    } else if (isNeedShowNotifBudget && !isNeedShowNotifClient) {
      navigate('/create-budget/client');
      return;
    } else {
      return;
    }
  }

  useEffect(() => {
    const isNeedNotif =
      isNeedShowNotifDataSource ||
      isNeedShowNotifClient ||
      isNeedShowNotifBudget;
    if (isLoadMainAndPossibleRoute && !showNotif && isNeedNotif) {
      setShowNotifAccount(true);
      if (isNeedShowNotifDataSource) {
        setAccountNotification({
          boldText: '',
          text: 'To get started click here to add a data source',
        });
      } else if (isNeedShowNotifClient && !isNeedShowNotifDataSource) {
        setAccountNotification({
          boldText: 'Step 2: ',
          text: 'click here to create a Client',
        });
      } else if (isNeedShowNotifBudget && !isNeedShowNotifClient) {
        setAccountNotification({
          boldText: 'Final Step: ',
          text: 'click here to create a Budget',
        });
      } else {
        return;
      }
    } else {
      setShowNotifAccount(false);
      setAccountNotification({
        boldText: '',
        text: '',
      });
    }
  }, [
    isNeedShowNotifDataSource,
    isNeedShowNotifClient,
    isNeedShowNotifBudget,
    isLoadMainAndPossibleRoute,
    getIsLoadMain,
    location,
  ]);

  useEffect(() => {
    (async () => {
      if (location.pathname.includes('confirm-invitation')) return;
      if (localStorage.getItem('ACCESS_TOKEN')) {
        apiv2.GET_ME().then(res => {
          dispatch(setMe(res.data));

          const { notifications } = res.data;
          let needCheckPaymentFailed = false;
          let needCheckTrialExpired = false;

          const subscriptionIsCanceled =
            notifications?.subscription_status === 'canceled';
          const userAddedPMAndActiveSubscription =
            notifications?.subscription_status &&
            notifications?.payment_method_attached;

          if (userAddedPMAndActiveSubscription) {
            needCheckPaymentFailed = true;
          } else {
            needCheckTrialExpired = true;
          }

          const isTrialPeriodInLastWeek =
            typeof notifications?.trial_remaining_days === 'number' &&
            notifications.trial_remaining_days >= 0 &&
            notifications.trial_remaining_days <= 7;

          let mainWarning = '';
          let instructionalWarning = '';
          let isValid = true;

          const subscriptionCanceledAndTrialExpired =
            subscriptionIsCanceled && notifications.trial_period_expired;

          if (
            needCheckPaymentFailed ||
            needCheckTrialExpired ||
            isTrialPeriodInLastWeek ||
            subscriptionIsCanceled
          ) {
            const permissionLevel = res.data.permission_level.name;

            const isPaymentDueWithinDaysGrace =
              notifications?.payment_remaining_days &&
              notifications.payment_remaining_days >= 0 &&
              notifications.payment_remaining_days <= 7;

            if (permissionLevel === PERMISSIONS.ADMIN) {
              if (needCheckPaymentFailed && notifications.payment_error) {
                if (isPaymentDueWithinDaysGrace) {
                  mainWarning = 'Make a payment towards your overdue balance.';
                  instructionalWarning = `You will not be able to view your dashboard in ${notifications.payment_remaining_days} days if this isn't resolved.`;
                } else {
                  mainWarning = 'Make a payment towards your overdue balance.';

                  isValid = false;
                  navigate('/settings/usage-billing');
                }
              } else if (
                needCheckTrialExpired &&
                notifications?.trial_period_expired
              ) {
                mainWarning = 'Free trial has expired.';
                instructionalWarning = `Click here to add a payment method.`;

                isValid = false;
                navigate('/settings/usage-billing');
              } else if (isTrialPeriodInLastWeek) {
                const remainDay = `${
                  notifications.trial_remaining_days > 0
                    ? `${notifications.trial_remaining_days} days`
                    : 'today'
                }`;

                mainWarning = `Free trial expires in ${remainDay}.`;
                instructionalWarning = `Click here to add a payment method.`;
              } else if (subscriptionCanceledAndTrialExpired) {
                isValid = false;
                instructionalWarning = `Your subscription has been cancelled by an admin. Please re-enable this in the settings to regain access to your dashboard.`;
              }
            } else if (
              permissionLevel === PERMISSIONS.READ_ONLY ||
              permissionLevel === PERMISSIONS.STANDARD
            ) {
              if (needCheckPaymentFailed && notifications.payment_error) {
                mainWarning = 'Overdue balance.';
                instructionalWarning = `Contact your account to admin regain access to your dashboard.`;

                if (!isPaymentDueWithinDaysGrace) {
                  isValid = false;
                }
              } else if (
                needCheckTrialExpired &&
                notifications?.trial_period_expired
              ) {
                isValid = false;
                mainWarning = 'Free trial has expired.';
                instructionalWarning = `Contact your account to admin regain access to your dashboard`;
              } else if (subscriptionCanceledAndTrialExpired) {
                isValid = false;
                instructionalWarning = `Your subscription has been cancelled by an admin. Please re-enable this in the settings to regain access to your dashboard.`;
              }

              navigate('/settings/user');
            }
          }

          setValidAccess(isValid);
          if (mainWarning || instructionalWarning) {
            setShowNotif(true);
            setBillingNotification({
              boldText: mainWarning,
              text: instructionalWarning,
            });
          } else {
            setShowNotif(false);
          }
        });
      }
    })();
  }, [needRefresh]);

  return (
    <>
      {(showNotif || showNotifAccount) && (
        <BillingNitif
          boldText={
            showNotifAccount
              ? accountNotification.boldText
              : billingNotification.boldText
          }
          text={
            showNotifAccount
              ? accountNotification.text
              : billingNotification.text
          }
          onClick={
            showNotifAccount
              ? linkCreator
              : () => {
                  billingNotification?.text?.includes(
                    'Click here to add a payment method',
                  ) && navigate('/settings/usage-billing');
                }
          }
        />
      )}
      {canShowHeaderAndSidebar && (
        <>
          <Header />
          <Sidebar />
        </>
      )}
      <AppRoutes validAccess={validAccess} />
    </>
  );
};

export default App;

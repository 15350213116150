import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import img2 from '../../assets/create-account-bg.jpg';
import Modal from '../../components/reusable/Modals/Modal';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { setTriggerGetMe } from '../../features/me/meSlice';
import css from './CreateAccount.module.css';

const CreateAccount = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    avgMonthlyAdSpend: '',
    showWelcomeBox: false,
    success: false,
    message: '',
  });
  const [referral, setReferral] = useState(null);

  const dispatch = useDispatch();
  const needRefresh = useSelector(state => state.me.triggerGetMe);

  const navigate = useNavigate();

  useEffect(() => {
    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        setReferral(window.Rewardful.referral);
      }
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    return new Promise(resolve => {
      if (!formData.showWelcomeBox) {
        api
          .CREATE_COMPANY({
            name: formData.companyName,
            avg_monthly_spend: formData.avgMonthlyAdSpend,
            referral
          })
          .then(response => {
            resolve();
            if (response.status == 201) {
              apiv2.GET_ME().then(response => {
                let {
                  id,
                  email,
                  company: {
                    id: copanyId,
                    name: companyName,
                    avg_monthly_spend: { label: avgMonthlyAdSpend },
                  },
                } = response.data;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'create_account',
                  user_id: id,
                  company_id: copanyId,
                  email: email,
                  company_name: companyName,
                  average_monthly_spend: avgMonthlyAdSpend,
                });

                if (window.innerWidth <= 768) {
                  setFormData({ ...formData, showWelcomeBox: true });
                } else {
                  navigate('/data-sources', { replace: true });
                  dispatch(setTriggerGetMe(!needRefresh))
                }
              });
            } else {
              let message =
                'Something went wrong, please try again after sometime.';
              if (response.status == 400) {
                if (response.data.name) {
                  message = response.data.name.join('<br>');
                }
                if (response.data.avg_monthly_spend) {
                  message = response.data.avg_monthly_spend.join('<br>');
                }
              }
              if (response.data.detail) {
                message = response.data.detail;
              }
              setFormData({ ...formData, success: false, message: message });
            }
          })
          .finally(() => {
          });
      } else {
        resolve();
        navigate('/data-sources', { replace: true });
      }
    });
  };

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <main>
      <div className={css.createAccount}>
        <img
          src={img2}
          alt=""
          style={{ minHeight: '100vh' }}
        />
        <div>
          <Modal
            type="createAccount"
            data={formData}
            onSubmit={handleSubmit}
            onChange={handleChange}
          />
        </div>
      </div>
    </main>
  );
};

export default CreateAccount;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage/LoginPage';
import { PERMISSIONS } from './../contants/permissions';
import CreateAccount from './../pages/CreateAccount/CreateAccount';
import CreateBudget from './../pages/CreateBudget/CreateBudget';
import CreateClient from './../pages/CreateClient/CreateClient';
import Dashboard from './../pages/Dashboard/Dashboard';
import DataSources from './../pages/DataSources/DataSources';
import ConfirmInvitationPage from './../pages/LoginPage/СonfirmInvitationPage';
import ResetPassword from './../pages/ResetPassword/ResetPassword';
import SignupPageV2 from './../pages/SignupPageV2';
import Team from './../pages/Team/Team';
import AutomatedRules from './../pages/UserSettings/AutomatedRules';
import UserSettings from './../pages/UserSettings/UserSettings';
import { ProtectedRoute } from './protectedRoute';

export const ROUTES = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SignupPageV2 />,
  },
  {
    path: '/create-account',
    element: <CreateAccount />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/confirm-invitation/:token',
    element: <ConfirmInvitationPage />,
  },
  {
    path: '/dashboard',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Dashboard />,
    needValidAccess: true,
  },
  {
    path: '/create-client',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateClient />,
    needValidAccess: true,
  },
  {
    path: '/create-client/:id',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateClient />,
    needValidAccess: true,
  },
  {
    path: '/create-budget/:step',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateBudget />,
    needValidAccess: true,
  },
  {
    path: '/create-budget/:step/:id',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateBudget />,
    needValidAccess: true,
  },
  {
    path: '/team',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <Team />,
    needValidAccess: true,
  },
  {
    path: '/data-sources',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <DataSources />,
    needValidAccess: true,
  },
  {
    path: '/data-sources/:source',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <DataSources />,
    needValidAccess: true,
  },
  {
    path: '/settings/automated-rules',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <AutomatedRules />,
  },
  {
    path: '/settings/automated-rules/:id',
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <AutomatedRules />,
  },
  {
    path: '/settings/user',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <UserSettings />,
  },
  {
    path: '/settings/account',
    allowedPermissions: [PERMISSIONS.ADMIN],
    element: <UserSettings />,
  },
  {
    path: '/settings/usage-billing',
    allowedPermissions: [PERMISSIONS.ADMIN],
    element: <UserSettings />,
  },
  {
    path: '*',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Navigate replace to="/dashboard" />,
  },
  {
    path: '*',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Navigate replace to="/dashboard" />,
  },
];

export const AppRoutes = ({ validAccess }) => {
  return (
    <Routes>
      {ROUTES.map(({ path, allowedPermissions, element, needValidAccess }, index) => (
        <Route
          key={path}
          path={path}
          element={
            allowedPermissions && allowedPermissions.length? (
              <ProtectedRoute
                allowedPermissions={allowedPermissions}
                validAccess={needValidAccess ? validAccess : true}
              >
                {element}
              </ProtectedRoute>
            ) : (
              element
            )
          }
        />
      ))}
    </Routes>
  );
};

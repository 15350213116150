import React from 'react';
import css from './BillingNotif.module.css';

export const BillingNitif = ({ boldText, text, onClick }) => {
  return (
    <div
      className={css.notif}
      onClick={onClick}
      style={text?.includes('Click') ? { cursor: 'pointer' } : {}}
    >
      <div className={css.notifImage}>i</div>
      <div className={css.notifText}>
        <b style={{ fontWeight: '900' }}>{boldText}</b> {text}
      </div>
    </div>
  );
};

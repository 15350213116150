import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import apiv2 from '../config/apiv2';
import { setMe } from '../features/me/meSlice';
import { useViewOptions } from '../hooks/useViewOptions';
import css from './../styles.module.css';

export const ProtectedRoute = ({
  children,
  allowedPermissions,
  validAccess = true,
}) => {
  //change trigger to !localStorage.getItem('ACCESS_TOKEN') or !cookies
  const [isAuthenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkAccess = async () => {
    try {
      const { data: currUser } = await apiv2.GET_ME();
      dispatch(setMe(currUser));
      setAuthenticated(true);

      const permissionOfCurrUser = currUser?.permission_level?.name;

      if (!allowedPermissions?.includes(permissionOfCurrUser)) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  localStorage.getItem('ACCESS_TOKEN') && useViewOptions();
  useEffect(() => {
    if (allowedPermissions.length && localStorage.getItem('ACCESS_TOKEN')) {
      checkAccess();
    }
  }, []);

  if (!localStorage.getItem('ACCESS_TOKEN')) {
    localStorage.removeItem('REFRESH_TOKEN');
    return <Navigate to="/login" replace />;
  }

  return (
    isAuthenticated &&
    validAccess && <main className={`${css.mainWrapper}`}>{children}</main>
  );
};

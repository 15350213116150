import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import Table from '../../Table';
import RuleCell from './RuleCell';
import { useTable } from '../../../../hooks/useTable';
import { RESOURCES } from '../../../../contants/resources';

const AutomatedRuleTable = () => {
  const [idSelected, setIdSelected] = useState(null);
  const [automatedRules, setAutomatedRules] = useState([]);

  const navigate = useNavigate();

  const { dataSource, isLoading } = useTable(
    {
      resource: RESOURCES.automated_rules,
    },
    [],
  );

  useEffect(() => {
    setAutomatedRules(dataSource);
  }, [dataSource]);

  const handleEditRule = id => {
    navigate(`/settings/automated-rules/${id}`);
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      enableColumnResizing: false,
    },
    {
      title: 'Rule',
      dataIndex: 'rule',
      enableColumnResizing: false,
      render: (record, _) => {
        return (
          <RuleCell
            record={record}
            idSelected={idSelected}
            onClickRuleStatus={setIdSelected}
            dataSource={dataSource}
            setDataSource={setAutomatedRules}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'action_type',
      enableColumnResizing: false,
    },
    {
      title: 'Created by',
      dataIndex: ['created_by', 'email'],
      enableColumnResizing: false,
    },
    {
      title: 'Client',
      dataIndex: ['client', 'name'],
      enableColumnResizing: false,
    },
    {
      title: 'Budget',
      dataIndex: ['budget', 'name'],
      enableColumnResizing: false,
    },
    {
      title: 'Notification',
      dataIndex: 'notification_type',
      enableColumnResizing: false,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      enableColumnResizing: false,
      render: ({ id }, _) => {
        return (
          <Button
            styled="primary"
            title="Edit"
            onClick={() => handleEditRule(id)}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={automatedRules}
      isLoading={isLoading}
    />
  );
};

export default AutomatedRuleTable;

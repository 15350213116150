import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../components/reusable/Button/Button';
import apiv2 from '../../../../../config/apiv2';
import { setRuleManagerState } from '../../../../../features/userSettings/userSettingsSlice';
import {
  mapperBodyAutomatedRule,
  mapperResToAutomatedRule,
  validateAutomatedRules,
} from '../../../../../helpers';
import {
  createSuccessAutomatedRule,
  error as errorNoti,
  updateSuccessAutomatedRule,
} from '../../../../../helpers/notyf';
import RuleAction from '../RuleAction';
import RuleDetails from '../RuleDetails';
import RuleNotification from '../RuleNotifications';
import styles from './styles.module.css';

const AutomatedRuleManager = ({ mode = 'create' }) => {
  const me = useSelector(state => state.me);
  const dispatch = useDispatch();

  const [clickCount, setClickCount] = useState(0);
  const [budgetsFromOnlyAccount, setBudgetsFromOnlyAccount] = useState(false);

  const [rule, setRule] = useState({
    name: '',
    status: 'E',
    client: null,
    budget: null,
    actionType: null,
    notificationType: null,
    notificationRecipients: mode === 'create' ? [me.email] : [],
    conditionCampaignStatus: null,
    conditionCampaignNamePattern: '',
    conditionCampaignNameRule: null,
    conditionType: null,
  });
  const [error, setError] = useState({
    name: null,
    status: null,
    clientId: null,
    budgetId: null,
    actionType: null,
    notificationType: null,
    notificationRecipients: null,
    conditionCampaignNamePattern: null,
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const idRuleIsValid = id && !isNaN(id);
    if (idRuleIsValid && mode === 'edit') {
      fetchAndSetAutomatedRuleWhenEdit(id);
    }
  }, [id, mode]);

  const fetchAndSetAutomatedRuleWhenEdit = id => {
    apiv2
      .GET_AUTOMATED_RULE(id)
      .then(res => {
        const { data } = res;
        const temp = mapperResToAutomatedRule(data);

        setRule(prev => ({
          ...prev,
          ...temp,
        }));
      })
      .catch(err => err);
  };

  const handleClickRule = async () => {
    if (!isValidAutomatedRules()) return;

    setClickCount(prev => prev + 1);
    if (clickCount > 0 || budgetsFromOnlyAccount) {
      const body = mapperBodyAutomatedRule(rule);

      try {
        if (mode === 'edit') {
          await updateAutomatedRule(rule.id, body);
        } else {
          await createAutomatedRule(body);
        }

        dispatch(setRuleManagerState(false));
      } catch (err) {
        errorNoti(err);
      }
    }
  };

  const createAutomatedRule = async body => {
    await apiv2.CREATE_AUTOMATED_RULE(body);
    createSuccessAutomatedRule();
  };

  const updateAutomatedRule = async (id, body) => {
    await apiv2.UPDATE_AUTOMATED_RULE(id, body);
    updateSuccessAutomatedRule();
    navigate('/settings/automated-rules', { replace: true });
  };

  const handleCancel = () => {
    dispatch(setRuleManagerState(false));
    navigate('/settings/automated-rules', { replace: true });
  };

  const isValidAutomatedRules = () => {
    const errorRule = validateAutomatedRules(rule);
    const isValid = Object.keys(errorRule).length === 0;

    setError(errorRule);
    return isValid;
  };

  return (
    <div className={styles.automatedRuleManager}>
      <div>
        <RuleDetails
          rule={rule}
          setRule={setRule}
          error={error}
          setBudgetsFromOnlyAccount={setBudgetsFromOnlyAccount}
        />
      </div>

      <div>
        <RuleAction
          rule={rule}
          setRule={setRule}
          error={error}
          setError={setError}
        />
      </div>

      <div>
        <RuleNotification rule={rule} setRule={setRule} error={error} />
      </div>

      {clickCount === 1 && !budgetsFromOnlyAccount && (
        <div className={styles.automatedRuleWarning}>
          <p>
            The selected budget has campaigns from multiple ad accounts. <br />{' '}
            Please select “Create Rule” once more to acknowledge that this rule
            will apply to campaigns across multiple accounts.
          </p>
        </div>
      )}

      <div className={styles.buttonContainer}>
        <Button
          title="Cancel"
          onClick={handleCancel}
          className={styles.buttonCancel}
        />
        <Button
          styled="submit"
          title={`${mode === 'edit' ? 'Save Changes' : 'Create Rule'}`}
          onClick={handleClickRule}
        />
      </div>
    </div>
  );
};

export default AutomatedRuleManager;

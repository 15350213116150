import 'chartkick/chart.js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { LineChart } from 'react-chartkick';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import close from '../../assets/CloseIcon.svg';
import edit from '../../assets/edit.svg';
import DatesPicker from '../../components/DatesPicker/DatesPicker';
import TableMenu from '../../components/TableMenu/TableMenu';
import CustomColumnSetModal from '../../components/reusable/Modals/CustomColumnSetModal';
import { CustomModal } from '../../components/reusable/Modals/CustomModal';
import Select from '../../components/reusable/Select/Select';
import DashboardTable from '../../components/reusable/Tables/DashboardTable';
import Tabs from '../../components/reusable/Tabs/Tabs';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import {
  VIEW_CHILD_TAB
} from '../../contants/dashboard';
import { addClient, setIsActive } from '../../features/createBudget/createBudgetSlice';
import {
  changeGraphTab,
  changeIsAllChecked,
  changeTab,
  changeTab2,
  setFilterElems,
  setGraphData,
  setPreviousTabs,
  setViewOptions
} from '../../features/dashboard/dashboardSlice';
import { toggleRefresh } from '../../features/root/rootSlice';
import { checkBudgetsCreateBySameClient, isOneBudgetCheck, removeCustomViewFromLocalStorage } from '../../helpers';
import { deleteSuccessCustomView, error, notyf, successBudgetDelete, successClientDelete } from '../../helpers/notyf';
import { useSelectPositionLeft } from '../../hooks/useSelectPositionLeft';
import { resetScrollLeftDashboardTable } from '../../utils';
import css from './Dashboard.module.css';

const customOption = (onDelete, onEdit,  { data, children, isSelected, isFocused, ...props }) => {
  const isModifyButton = children === VIEW_CHILD_TAB.MODIFY_COLUMNS.label
  const classNameOptionsIsButton = `${css.viewOptionModifyColumn}`
  const classNameOption = `${css.viewOption} ${
    isSelected ? css.viewOptionSelected : ''
  }`

  const handleDelete = async (e) => {
    e.stopPropagation()
    if(onDelete) await onDelete(data)
  }
  
  const handleEdit = async (e) => {
    e.stopPropagation()
    if(onDelete) await onEdit(data)
  }

  const renderChildren = () => {
    const isCustomView = Object.values(VIEW_CHILD_TAB).every(
      view => view.label !== children,
    );

    if (isCustomView) {
      const nameCustomView = JSON.parse(children)?.name

      return (
        <div className={css.customViewSelection}>
          <div>
            <div className={css.customViewLabelSelection}>{nameCustomView}</div>
          </div>
          <div className={css.customViewAction}>
            <img src={edit} alt='edit_icon' onClick={handleEdit}/>
            <img src={close} alt='close_icon' className={css.closeIcon} onClick={handleDelete}/>
          </div>
        </div>
        );
    }
    else if (isModifyButton) return children;
    else return <div className={css.labelContainer}>{children}</div>;
  };

  return (
    <components.Option
      {...props}
      className={
        isModifyButton ? `${classNameOptionsIsButton}` : `${classNameOption}`
      }
    >
      {renderChildren()}
    </components.Option>
  );
};

const customValueContainer = ({ children, ...props }) => {
  const tempChildren = [...children];
  if (props.hasValue) {
    let value = props.getValue()[0].label;
    const isCustomView = Object.values(VIEW_CHILD_TAB).every(
      view => view.label !== value,
    );

    if(isCustomView) {
      value = JSON.parse(value)?.name
    }
    
    tempChildren[0] = <span className={css.valueSelect}>View: {value}</span>;
  }

  return (
    <components.ValueContainer {...props}>
      {props.hasValue ? tempChildren : children}
    </components.ValueContainer>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();

  const { formData, isActive, tab: budgetTab } = useSelector(state => state.createBudget);
  const { tab, tab2, previousViewTabs, graphTab, graphData, isAllChecked, tableData } =
    useSelector(state => state.dashboard);
  const { isRefresh } = useSelector(state => state.root);
  const { startDate, endDate, filterElems } = useSelector(
    state => state.dashboard,
  );
  const { viewOptions } = useSelector(state => state.dashboard);

  const [customViewOptionSelected, setCustomViewOptionSelected] = useState(null);

  const [removeClientsModal, setRemoveClientsModal] = useState(false);
  const [removeBudgetsModal, setRemoveBudgetsModal] = useState(false);
  const [removeCampaignsModal, setRemoveCampaignsModal] = useState(false);
  const [isOpenCustomColumnsModal, setIsOpenCustomColumnsModal] = useState(false);
  const [isOpenRemoveCustomViewModal, setIsOpenRemoveCustomViewModal] = useState(false);
 
  const [isShowingChart, setIsShowingChart] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [viewChildTabOption, setViewChildTabOption] = useState(
    VIEW_CHILD_TAB.BUDGET_PACING,
  );

  const [tableInfo, setTableInfo] = useState({
    head: [],
    colums: [
      {
        isChecked: false,
        info: '',
        items: [
          {
            render() {
              return <span onClick={calc}>gg</span>;
            },
          },
          {
            render() {
              return <span onClick={calc}>gg</span>;
            },
          },
        ],
      },
    ],
  });

  const dispatch = useDispatch();

  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  const checked = tableData.filter(
    el => el.isChecked && el.row_type !== 'total',
  );

  useEffect(() => {
    if (JSON.stringify(graphData) === '{}') {
      document.getElementById('users-chart').innerHTML =
        "To get started, add a data source, client, and budget. Alternatively, you can check out our guided steps <a href='https://docs.airtomic.co/overview/getting-started' target='_blank' rel='noreferrer'>here</a>";
    }
  }, [graphData, tab, tab2, graphTab]);

  const handleChangeTabView = option => {
    resetScrollLeftDashboardTable()

    if (
      option.value === VIEW_CHILD_TAB.PERFORMANCE.value ||
      option.value === VIEW_CHILD_TAB.BUDGET_PACING.value
    ) {
      handlePerformanceOrBudgetPacingViewTab(option)
    } else if (option.value === VIEW_CHILD_TAB.MODIFY_COLUMNS.value) {
      setIsOpenCustomColumnsModal(true);
    } else {
      handleCustomViewTab(option)
    }
  };

  const handlePerformanceOrBudgetPacingViewTab = option => {
    const tab = option.value;

    setViewChildTabOption(option);
    dispatch(changeTab2(tab));
  };

  const handleCustomViewTab = option => {
    const tab = option.label;
    const customView = JSON.parse(tab)
    
    if(customView.type === "browser") {
      notyf.error("Please save custom view")
      return
    }

    setViewChildTabOption(option);
    dispatch(changeTab2(tab));
  };

  const prepareGhartData = modify => {
    let box = {};
    modify?.forEach(info => {
      box[`${info.date}`] = info.spend;
    });
    return box;
  };

  const updateHeadTable = type => {
    // see if renderCheckbox  set the render function to return checkbox
    const prepareTitle = {
      clientBunget: [
        'renderCheckbox',
        'Client',
        'Budget amount',
        'Spend',
        'Trend',
        'Ideal daily spend',
        'Yesterday’s spend',
        '3 Day Avg.Spend',
        '7 Day Avg.Spend',
        'Lost IS Budget',
        'Lost IS Rank',
      ],
    };
    const creator = array => {
      return array.map(item => {
        if (item === 'renderCheckbox') {
          return {
            render() {
              return <span onClick={calc}>Checkbox</span>;
            },
          };
        } else {
          return {
            render() {
              return <span>{item}</span>;
            },
          };
        }
      });
    };
    switch (type) {
      case 'clientBudget':
        setTableInfo(prev => ({
          ...prev,
          head: creator(prepareTitle.clientBunget),
        }));
        return;
      default:
        return;
    }
  };

  function calc() {
    debugger;
  }

  const handleGetGraphData = params => {
    setIsLoading(true);
    dispatch(changeIsAllChecked(false));
    updateHeadTable('clientBudget');
    const reqParams = { group_by: graphTab, ...(params ?? {}) };
    if (startDate && endDate) {
      reqParams.date_from = moment(startDate).format('YYYY-MM-DD');
      reqParams.date_to = moment(endDate).format('YYYY-MM-DD');
    }
    api.GET_GRAPH(reqParams).then(res => {
      dispatch(setGraphData(prepareGhartData(res)));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    handleGetGraphData();
  }, [graphTab, startDate, endDate, isRefresh]);

  useEffect(() => {
    if (tab === 'campaigns' && tab2 === 'budget-pacing') {
      setViewChildTabOption(VIEW_CHILD_TAB.BUDGET_PACING);

      dispatch(changeTab('clients'));
      dispatch(changeTab2('budget-pacing'));
    }
  }, [tab, tab2]);

  const handleTabChange = (newTab, e) => {
    resetScrollLeftDashboardTable()

    dispatch(setPreviousTabs(`${tab}/${tab2}`))
    if (newTab === 'campaigns') {
      dispatch(changeTab(newTab));

      // case custom view 
      const isCustomView = tab2 !== 'campaigns' && tab2 !== 'budget-pacing';
      if (!isCustomView) {
        dispatch(changeTab2('performance'));
        setViewChildTabOption(VIEW_CHILD_TAB.PERFORMANCE);
      }
    } else if (tab === 'campaigns' && tab2 === 'performance') {
      const [parPrevViewTab, childPrevViewTab] = previousViewTabs.split('/');
      let viewChildTabOption = VIEW_CHILD_TAB.BUDGET_PACING;

      if (parPrevViewTab === newTab) {
        dispatch(changeTab2(childPrevViewTab));
        viewChildTabOption =
          childPrevViewTab === 'budget-pacing'
            ? VIEW_CHILD_TAB.BUDGET_PACING
            : VIEW_CHILD_TAB.PERFORMANCE;
      } else {
        dispatch(changeTab2('budget-pacing'));
      }
      
      dispatch(changeTab(newTab));
      setViewChildTabOption(viewChildTabOption);
    } else {
      dispatch(changeTab(newTab));
    }
  };

  const handleShowHide = () => {
    setIsShowingChart(p => !p);
  };

  const handleGraphTabChange = tab => {
    dispatch(changeGraphTab(tab));
  };

  const handleDeleteClients = () => {
    const ids = tableData
      .filter(el => el.isChecked && el.row_type !== 'total')
      .map(el => el.client_id);
    api
      .DELETE_CLIENTS(ids)
      .then(() => {
        setRemoveClientsModal(false);
        dispatch(toggleRefresh());
        successClientDelete();
      })
      .catch(() => {
        setRemoveClientsModal(false);
      });
  };

  const getSelectedText = () => {
    let tabName = '';

    if (tab === 'budgets') {
      tabName = 'Budgets';
    } else if (tab === 'campaigns') {
      tabName = 'Campaigns';
    } else {
      tabName = 'Clients';
    }

    const selectedItems = tableData.filter(
      item => item.isChecked && item.row_type !== 'total',
    );
    const length =
      selectedItems.length !== 0 ? selectedItems.length : tableData.length - 1;
    const text = length === -1 ? '' : `${tabName} Selected: ${length}`;

    return text;
  };

  const handleDeleteBudgets = () => {
    const ids = tableData
      .filter(el => el.isChecked && el.row_type !== 'total')
      .map(el => el.budget_id);
    api
      .DELETE_BUDGETS(ids)
      .then(() => {
        setRemoveBudgetsModal(false);
        dispatch(toggleRefresh());
        successBudgetDelete();
      })
      .catch(() => {
        setRemoveBudgetsModal(false);
      });
  };

  const handleDeleteCampaigns = async () => {
    const selectedCampaigns = tableData.filter(
      el => el.isChecked && el.row_type !== 'total',
    );
    const budgetIds = [
      ...new Set(selectedCampaigns.map(campaign => campaign.budget_id)),
    ];

    await handleRemoveCampaignsOfBudget(budgetIds, selectedCampaigns);

    dispatch(toggleRefresh());
    setRemoveCampaignsModal(false);
  };

  useEffect(() => {
    const type = tab + '-' + tab2;

    if (
      type === 'budgets-budget-pacing' ||
      type === 'clients-performance' ||
      type === 'campaigns-performance'
    ) {
      const params = {
        budgets_id: filterElems.length
          ? filterElems.map(elem => elem.budget_id).join(',')
          : null,
      };
      handleGetGraphData(params);
    }

    if (type === 'clients-budget-pacing' || type === 'budgets-performance') {
      const params = {
        clients_id: filterElems.length
          ? filterElems.map(elem => elem.client_id).join(',')
          : null,
      };

      handleGetGraphData(params);
    }
  }, [filterElems]);

  const handleRemoveCampaignsOfBudget = async (
    budgetIds,
    selectedCampaigns,
  ) => {
    const budgets = await Promise.allSettled(
      budgetIds.map(budgetId => api.GET_BUDGET(budgetId)),
    ).then(results => results.map(result => result.value));

    await Promise.allSettled(
      budgets.map(budget => {
        const updateCampaignIds = getUpdateCampaignIds(
          budget,
          selectedCampaigns,
        );
        const body = getBodyWhenPatchBudget(
          budget,
          budgetTab,
          updateCampaignIds,
          formData,
        );

        return api.UPDATE_BUDGET(body, budget.id);
      }),
    );
  };

  const getUpdateCampaignIds = (budget, selectedCampaign) => {
    const selectedCampaignIdOfBudget = selectedCampaign
      .filter(campaign => campaign.budget_id === budget.id)
      .map(campaign => campaign.campaign_id);
    const updateCampaignIds = budget.campaigns
      .filter(campaign => !selectedCampaignIdOfBudget.includes(campaign.id))
      .map(campaign => campaign.id);

    return updateCampaignIds;
  };

  const getBodyWhenPatchBudget = (
    budget,
    budgetTab,
    updatedCampaignIds,
    formData,
  ) => {
    const body = {
      client_id: budget.client.id,
      amount: +budget.amount,
      name: budget.name,
      type: budgetTab.charAt(0).toUpperCase() + budgetTab.slice(1),
      campaigns_id: updatedCampaignIds,
    };

    if (budgetTab === 'monthly') {
      body.month_start_day = formData.month_start_day.value;
    }

    if (budgetTab === 'weekly') {
      body.week_start_day = formData.week_start_day.value;
    }

    if (budgetTab === 'custom') {
      body.start_date =
        typeof formData.start_date === 'string'
          ? formData.start_date
          : `${formData.start_date.getFullYear()}-${
              formData.start_date.getMonth() + 1
            }-${formData.start_date.getDate()}`;
      body.end_date =
        typeof formData.end_date === 'string'
          ? formData.end_date
          : `${formData.end_date.getFullYear()}-${
              formData.end_date.getMonth() + 1
            }-${formData.end_date.getDate()}`;
      body.type = formData.type.label;
    }

    return body;
  };

  const handleAddNewBudget = () => {
    const client = {
      value: filterElems[0].client_id,
      label: filterElems[0].client_name,
    }
    
    dispatch(addClient(client));
    dispatch(setIsActive({ ...isActive, step2: true }));
    navigate('/create-budget/budget');
  }

  const getCurrency = () => {
    let isSame = true;

    tableData.forEach(el => {
      if (el.currency !== tableData[0].currency) {
        isSame = false;
      }
    });

    return isSame ? tableData?.[0]?.currency ?? '' : '';
  };

  const isOneBudget = isOneBudgetCheck(tab, tableData, filterElems);
  const isOneClient =
    tab === 'budgets' &&
    tab2 === 'budget-pacing' &&
    filterElems.length !== 0 &&
    checkBudgetsCreateBySameClient(tableData);

  const handleCloseModal = () => {
    setCustomViewOptionSelected(null)
    setIsOpenCustomColumnsModal(false)
  }

  const handleOpenDeleteCustomView = (customViewOption) => {
    setCustomViewOptionSelected({...customViewOption})
    setIsOpenRemoveCustomViewModal(true)
  }

  const handleDeleteCustomView = async () => {
    try {
      const customViewRaw = customViewOptionSelected.label;
      const customView = JSON.parse(customViewRaw)

      const removedArr = [...viewOptions].filter(view => view.value !== customViewOptionSelected.value);
      if(customView.type === "browser"){
        removeCustomViewFromLocalStorage(customViewOptionSelected.value)
      }
      else {
      await apiv2.DELETE_CUSTOM_VIEW(customViewOptionSelected.value);
    }

      setCustomViewOptionSelected(null)
      dispatch(setViewOptions(removedArr))
      deleteSuccessCustomView()
    } catch (err) {
      error(err);
    } finally {
      setIsOpenRemoveCustomViewModal(false);
    }

    setIsOpenRemoveCustomViewModal(false)
  }

  const handleEditCustomView = async (customViewOption) => {
    setCustomViewOptionSelected({...customViewOption})
    setIsOpenCustomColumnsModal(true)
  }

  const handleCloseRemoveCustomViewModal = () => {
    setCustomViewOptionSelected(null)
    setIsOpenRemoveCustomViewModal(false)
  }

  return (
    <div className={`${css.main}`}>
      {isLoading && isShowingChart && <div className={`${css.loadingBar}`} />}
      <div
        className={`${
          isShowingChart ? css.calendarContainer + ' ' + css.mb : ''
        }`}
      >
        <div className={css.header}>
          <Tabs
            tab={graphTab}
            handleTabChange={handleGraphTabChange}
            type="graph"
          />
          <DatesPicker />
        </div>
        {isShowingChart && (
          <LineChart
            id="users-chart"
            prefix={getCurrency()}
            loading="Loading..."
            empty=" "
            thousands=","
            data={graphData}
          />
        )}
      </div>
      {removeClientsModal && (
        <CustomModal
          title={`Remove ${checked.length} ${
            checked.length === 1 ? 'Client' : 'Clients'
          }`}
          subtitle="Deleting clients will remove them from your dashboard and they will no longer be tracked"
          maxWidth={650}
          onClose={setRemoveClientsModal}
          okText="Remove"
          onOk={handleDeleteClients}
        />
      )}
      {removeBudgetsModal && (
        <CustomModal
          title={`Remove ${checked.length} ${
            checked.length === 1 ? 'Budget' : 'Budgets'
          }`}
          subtitle="Deleting budgets will remove them from your dashboard and they will no longer be tracked"
          maxWidth={650}
          onClose={setRemoveBudgetsModal}
          okText="Remove"
          onOk={handleDeleteBudgets}
        />
      )}

      {removeCampaignsModal && (
        <CustomModal
          title={`Remove ${checked.length} ${
            checked.length === 1 ? 'Campaign' : 'Campaigns'
          }`}
          onClose={setRemoveCampaignsModal}
          okText="Remove"
          onOk={handleDeleteCampaigns}
        >
          <p className={css.modalText}>
            Removing campaigns will remove them from the relevant Budget and
            they will no longer be tracked.
          </p>
          <p className={css.modalText}>
            This action will not change anything in the relevant platforms. This
            will only remove them from your Airtomic dashboard.
          </p>
        </CustomModal>
      )}

      {isOpenRemoveCustomViewModal && (
        <CustomModal
          title="Delete custom view"
          onClose={handleCloseRemoveCustomViewModal}
          subtitle={`Delete saved column custom view ?`}
          onOk={handleDeleteCustomView}
        >
        </CustomModal>
      )}

      {isOpenCustomColumnsModal && (
        <CustomColumnSetModal onClose={handleCloseModal} customViewOptionSelected={customViewOptionSelected} />
      )}

      <div className={css.content}>
        <div className={css.container}>
          <div
            className={`${css.tabsWrapper} ${
              !((isAllChecked && checked.length) || checked.length)
                ? css.padding
                : ''
            }`}
          >
            {(isAllChecked && checked.length) || checked.length ? (
              <TableMenu
                setRemoveCampaignsModal={setRemoveCampaignsModal}
                setRemoveClientsModal={setRemoveClientsModal}
                setRemoveBudgetsModal={setRemoveBudgetsModal}
                // setFilterElems={setFilterElems}
              />
            ) : (
              <div className={`${css.tabContainer}`}>
                <Tabs
                  tab={tab}
                  handleTabChange={handleTabChange}
                  type="dashboard"
                  handleShowHide={handleShowHide}
                  isShowing={isShowingChart}
                  getSelectedText={getSelectedText}
                  isShowingFilters={filterElems.length}
                  isShowAddOrRemoveCampaignBtn={isOneBudget}
                  isShowAddNewBudgetBtn={isOneClient}
                  handleClearFilters={() => {
                    dispatch(setFilterElems([]));
                  }}
                  handleAddNewBudget={handleAddNewBudget}
                  handleChange={() => {
                    let budgetId;

                    if (!filterElems[0].budget_id) {
                      const data = tableData.filter(
                        item => item.row_type !== 'total',
                      );
                      const firstBudgetId = data[0].budget_id;
                      const sameBudgetId = data.every(
                        item => item.budget_id === firstBudgetId,
                      );

                      if (sameBudgetId) {
                        budgetId = firstBudgetId;
                      } else {
                        budgetId = filterElems[0].budget_id;
                      }
                    } else {
                      budgetId = filterElems[0].budget_id;
                    }

                    navigate(`/create-budget/campaigns/${budgetId}`, {
                      id: budgetId,
                    });
                  }}
                />
                <div ref={wrapperSelectRef} className={`${css.selectWrapper}`}>
                  <Select
                    options={viewOptions}
                    value={viewChildTabOption}
                    onChange={handleChangeTabView}
                    className={css.viewSelect}
                    customOption={props =>
                      customOption(handleOpenDeleteCustomView, handleEditCustomView, props)
                    }
                    customValueContainer={customValueContainer}
                    stylePlaceholder={{
                      margin: '0',
                      fontSize: '1.4rem',
                    }}
                    styleSingleValue={{
                      whiteSpace: 'nowrap',
                      margin: '0',
                      fontSize: '1.4rem',
                    }}
                    styleInput={{
                      margin: '0',
                      minWidth: '0',
                      height: '0',
                    }}
                    styleMenuPortal={{
                      width: '36rem',
                      left: selectPosLeft,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={css.tableWrapper}>
            <DashboardTable
              type={tab + '-' + tab2}
              filterElems={filterElems}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              // setFilterElems={setFilterElems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import { useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import americanExpress from '../../assets/americanExpress.svg';
import amex from '../../assets/amex.png';
import diners from '../../assets/diners.png';
import discover from '../../assets/discover.png';
import eftpos_au from '../../assets/eftpos_au.png';
import jcb from '../../assets/jcb.png';
import maestro from '../../assets/maestro.svg';
import mastercard from '../../assets/mastercard.svg';
import unionpay from '../../assets/unionpay.png';
import unknown from '../../assets/unknown.png';
import visa from '../../assets/visa.svg';
import Button from '../../components/reusable/Button/Button';
import CustomPopup from '../../components/reusable/Popups/CustomPopup';
import apiv2 from '../../config/apiv2';
import { setTriggerGetMe } from '../../features/me/meSlice';
import {
  setCardData,
  setIsPayment
} from '../../features/userSettings/userSettingsSlice';
import { errorBilling, successBilling } from '../../helpers/notyf';
import { formatUnixTimestamp } from '../../utils';
import css from './UserSettings.module.css';

const BRAND_CARD = {
  amex: {
    src: amex,
    alt: 'amex',
  },
  diners: {
    src: diners,
    alt: 'diners',
  },
  discover: {
    src: discover,
    alt: 'discover',
  },
  eftpos_au: {
    src: eftpos_au,
    alt: 'eftpos_au',
  },
  jcb: {
    src: jcb,
    alt: 'jcb',
  },
  mastercard: {
    src: mastercard,
    alt: 'mastercard',
  },
  unionpay: {
    src: unionpay,
    alt: 'unionpay',
  },
  visa: {
    src: visa,
    alt: 'visa',
  },
  unknown: {
    src: unknown,
    alt: 'unknown',
  },
};

const Payment = ({ setInvoices }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCancelSubModal, setIsOpenCancelSubModal] = useState(false);
  const [invoiceUpcoming, setInvoiceUpcoming] = useState(false);

  const needRefresh = useSelector(state => state.me.triggerGetMe);
  const company = useSelector(state => state.me.company);
  const cardData = useSelector(state => state.userSettings.cardData);

  const dispatch = useDispatch();
  const stripe = useStripe();

  const firstChar = cardData?.stripe_card_brand?.charAt(0)?.toUpperCase();
  const restOfString = cardData?.stripe_card_brand?.slice(1);

  const formattedString =
    firstChar && restOfString ? firstChar + restOfString : '';

  const isPaymentErrorRequireUserAction =
    cardData?.has_unpaid_invoice &&
    cardData?.unpaid_invoice?.payment_intent?.status === 'requires_action';

  const handlePayment = () => {
    dispatch(setIsPayment(true));
  };

  // DEVELOPING
  const tryAgainPayManuallyInvoice = async () => {
    try {
      const client_secret =
        cardData?.unpaid_invoice?.payment_intent?.client_secret;
      if (client_secret) {
        const { data, error } = await stripe.confirmCardPayment(client_secret);

        if (error) {
          throw new Error(error.message);
        }

        successBilling('Pay successfully');
        dispatch(setTriggerGetMe(!needRefresh));
        apiv2.INVOICES().then(res => setInvoices && setInvoices(res.data));
      }
    } catch (error) {
      let message = error?.message;
      if (error instanceof AxiosError) {
        message = error?.response?.data && error?.response?.data.error;
      }
      errorBilling(message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickCancelSubscription = async () => {
    try {
      const res = await apiv2.INVOICE_UPCOMING(company?.id);
      if (res?.data) setInvoiceUpcoming(res.data);
      setIsOpenCancelSubModal(true);
    } catch (error) {
      let message = error?.message;
      if (error instanceof AxiosError) {
        message = error?.response?.data && error?.response?.data.error;
      }
      errorBilling(message || error?.message);
    }
  };

  const handleCancelSubscription = () => {
    setIsOpenCancelSubModal(false);
  };

  const handleConfirmCancelSubscription = async () => {
    try {
      await apiv2.CANCEL_CONFIRMATION(company?.id)
      
      successBilling("Cancel subscription successfully! It will be automatically billed soon.")
      setIsOpenCancelSubModal(false)
      apiv2
      .COMPANIES_BILLING_GET()
      .then(res => dispatch(setCardData(res.data)));
      dispatch(setTriggerGetMe(!needRefresh))
    } catch (error) {
      let message = error?.message;
      if (error instanceof AxiosError) {
        message = error?.response?.data && error?.response?.data.error;
      }
      errorBilling(message || error?.message);
    }
  }

  const handleReNewSubscription = async () => {
    try {
      await apiv2.RENEW_SUBSCRIPTION({
        "company_id": company?.id
      })
      
      successBilling("Renew subscription successfully!")
      dispatch(setTriggerGetMe(!needRefresh))
    } catch (error) {
      let message = error?.message;
      if (error instanceof AxiosError) {
        message = error?.response?.data && error?.response?.data.error;
      }
      errorBilling(message || error?.message);
    }
  }

  const renderPaymentError = () => {
    const isPaymentErrorRequirePM =
      cardData?.has_unpaid_invoice &&
      cardData?.unpaid_invoice?.payment_intent?.status ===
        'requires_payment_method';
    const isAllRetriesPaymentError =
      isPaymentErrorRequirePM &&
      !cardData?.unpaid_invoice?.next_payment_attempt;
    let errorMessage = '';

    if (isAllRetriesPaymentError) {
      errorMessage = 'Payment failed. Update your card details.';
    } else if (isPaymentErrorRequirePM) {
      errorMessage = `Payment failed. Payment will be retried ${formatUnixTimestamp(
        cardData?.unpaid_invoice?.next_payment_attempt,
      )}.`;
    } else if (isPaymentErrorRequireUserAction) {
      errorMessage =
        'Payment was initiated which requires an additional user action.';
    }

    return errorMessage ? (
      <div className={css.card__error}>{errorMessage}</div>
    ) : (
      <></>
    );
  };

  return (
    <div className={css.containerPaiment}>
      <h2 className={css.title}>Payment Information</h2>
      <div className={`${css.content} ${css.center}`}>
        <div className={css.payment}>
          {isLoading ? (
            <div className={css.containerForLoader}>
              <div className={css.loader}></div>
            </div>
          ) : (
            <div>
              {cardData?.stripe_payment_method_id ? (
                <div style={{ display: 'flex' }}>
                  <img
                    alt={BRAND_CARD[cardData.stripe_card_brand].alt}
                    className={css.image}
                    src={BRAND_CARD[cardData.stripe_card_brand].src}
                  />
                  <div className={css.wrapPaymant}>
                    <div className={css.card_paymentTitle}>
                      Current payment method
                    </div>
                    <div className={css.card_infoText}>
                      {formattedString} ending in {cardData?.stripe_card_last4}
                    </div>
                    {renderPaymentError()}
                  </div>
                </div>
              ) : (
                <>
                  <p className={css.paymentTitle}>No payment method added</p>
                  <p className={css.infoText}>
                    Add a payment method before your free trial ends
                  </p>
                  <div className={css.imgsContainer}>
                    <img alt="mastercard" src={mastercard} />
                    <img alt="visa" src={visa} />
                    <img alt="americanExpress" src={americanExpress} />
                    <img alt="maestro" src={maestro} />
                  </div>
                </>
              )}
            </div>
          )}

          <div className={`${css.containerPaymentActions}`}>
            <Button
              styled="payment"
              title={
                !cardData?.stripe_payment_method_id
                  ? 'Add Payment Method'
                  : 'Update Payment Method'
              }
              disabled={isLoading}
              onClick={handlePayment}
            />
            {isPaymentErrorRequireUserAction && !isLoading && (
              <Button
                styled="payment"
                title={'Try Again'}
                onClick={tryAgainPayManuallyInvoice}
              />
            )}
            {cardData?.stripe_subscription_id &&
              cardData?.stripe_subscription_status !== 'canceled' &&
              !isLoading && (
                <Button
                  styled="cancel_subscription"
                  title="Cancel subscription"
                  onClick={handleClickCancelSubscription}
                />
              )}
              {cardData?.stripe_subscription_status === 'canceled' &&
              !isLoading && (
                <Button
                  styled="payment"
                  title="Re-new subscription"
                  onClick={handleReNewSubscription}
                />
              )}
            <CustomPopup
              title="Cancel subscription?"
              isOpen={isOpenCancelSubModal}
              onOk={handleConfirmCancelSubscription}
              onCancel={handleCancelSubscription}
            >
              <p className={css.text}>
                {`Are you sure you want to continue? Canceling a subscription
                cannot be undone, you will lose access${
                  invoiceUpcoming?.total
                    ? ` and you will be charged
                the outstanding amount of ${invoiceUpcoming.total / 100}$`
                    : ''
                }`}
              </p>
            </CustomPopup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

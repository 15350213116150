import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { STATUS_RULES } from '../contants/automatedRules';

export const notyf = new Notyf({
  duration: 7000,
  types: [
    {
      type: 'error',
      backgroundColor: 'grey',
      message: 'Whoops, something went wrong, please try again!',
    },
  ],
});

export const displayErrorMessage = response => {
  let error = 'Error';

  if (response?.response?.data) {
    let errors = response.response.data;
    if (typeof response.response.data.detail === 'string') {
      error = response.response.data.detail;
    } else {
      let errorName = Object.keys(errors).length
        ? Object.keys(errors)[0]
        : null;
      error = errors[errorName].length ? errors[errorName][0] : 'Error';
    }
  }

  notyf.error(error);
};

export const successLogin = () => {
  notyf.success('You have successfully authorized!');

  return true;
};

export const successUpdateAccount = () => {
  notyf.success('Account data was successfully updated');

  return true;
};

export const successResetPassword = () => {
  notyf.success('Password was successfully updated');

  return true;
};

export const successChangeCompanyName = () => {
  notyf.success('Company name was successfully updated');

  return true;
};

export const successCreateClient = () => {
  notyf.success('You have successfully created a client!');

  return true;
};

export const successInviteUser = () => {
  notyf.success('You have successfully invited a user!');

  return true;
};

export const successCreateBudget = () => {
  notyf.success('Processing your request. Please Wait.');

  return true;
};

export const successUpdateBudget = () => {
  notyf.success('Budget was successfully updated');

  return true;
};

export const successUpdateClient = () => {
  notyf.success('You have successfully updated a client!');

  return true;
};

export const successAddDataSource = (type) => {
  notyf.success(`You have successfully added ${type} Account`);

  return true;
}

export const successSourceDelete = () => {
  notyf.success('You have successfully disconnected source!');

  return true;
};

export const successBudgetDelete = () => {
  notyf.success('You have successfully remove budget!');

  return true;
};

export const successClientDelete = () => {
  notyf.success('You have successfully remove client!');

  return true;
};

export const successRemoveAccess = () => {
  notyf.success(`You have successfully removed user's access!`);

  return true;
};

export const successPermissionsChanged = () => {
  notyf.success(`You have successfully changed user's access!`);

  return true;
};

export const failLogin = () => {
  notyf.error('Login failed');
};

export const failCreateClient = () => {
  notyf.error('Create client failed');
};

export const failUpdateClient = () => {
  notyf.error('Update client failed');
};

export const failCreateBudget = () => {
  notyf.error('Create budget failed');
};

export const failUpdateBudget = () => {
  notyf.error('Update budget failed');
};

export const failChangeOwnAccessLevelOfAdmin = () => {
  notyf.error('Please add another admin before removing yourself as an admin');
};

export const failGetDataOnDashboard = () => {
  notyf.error('Please connect a Data Source to get started');
};

export const errorBilling = data => {
  notyf.error(data);
};
export const successBilling = message => {
  notyf.success(message);
};

export const error = data => {
  notyf.error(data.message);
};

export const fail = () => {
  notyf.error();
};

export const successNotif = () => {
  notyf.success('Request sent successfully');
};

export const successUpdateStatusAutomatedRule = status => {
  notyf.success(
    `You have successfully ${
      status === STATUS_RULES.ENABLE ? 'enabled' : 'paused'
    } rule!`,
  );
};

export const removeSuccessAutomatedRule = () => {
  notyf.success("You have successfully removed rule")
}

export const createSuccessAutomatedRule = () => {
  notyf.success("You have successfully created rule")
}

export const updateSuccessAutomatedRule = () => {
  notyf.success("You have successfully updated rule")
}

export const deleteSuccessCustomView = () => {
  notyf.success("You have successfully updated custom view")
}
export const MAX_RETRIES = 15
export const BACKOFF_FACTOR = 5

export const PROTECTED_ROUTES = [
    "dashboard",
    "create-client",
    "create-budget",
    "team",
    "data-sources",
    "settings/automated-rules",
    "settings/user",
    "settings/account",
    "settings/usage-billing"
]


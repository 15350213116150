import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from '../../../components/reusable/Modals/Modal';
import api from '../../../config/api';
import apiv2 from '../../../config/apiv2';
import { setMe } from '../../../features/me/meSlice';
import css from './../SignupPage.module.css';

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    success: false,
    activated: false,
    restorePassword: false,
    message: '',
    remember: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookies] = useState();

  useEffect(() => {
    const cookies = document.cookie.split(';');
    const array = [];
    cookies.forEach(elem => {
      const element = elem.split('=');
      array.push({
        [element[0]]: element[1],
      });
      setCookies(array);
    });
  }, []);

  const handleGoogleLogin = useGoogleLogin({
    scope:
      'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    //flow: 'auth-code',
    onSuccess: response => {
      api
        .GOOGLE_LOGIN({ access_token: response.access_token, payload: cookies })
        .then(response => {
          if (response == 201) {
            apiv2.GET_ME().then(response => {
              dispatch(setMe(response.data));
              if (response.data.has_finished_signup) {
                let {
                  id,
                  email,
                  company: { id: copanyId },
                } = response.data;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'login',
                  user_id: id,
                  company_id: copanyId,
                  email: email,
                });
                navigate('/dashboard', { replace: true });
              } else {
                let { id, email } = response.data;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'sign_up',
                  user_id: id,
                  email: email,
                });
                navigate('/create-account', { replace: true });
              }
            });
          } else {
            setFormData({
              ...formData,
              success: false,
              message: 'Something went wrong, please try again after sometime.',
            });
          }
        });
    },
    onError: () => {
      setFormData({
        ...formData,
        success: false,
        message: 'Something went wrong, please try again after sometime.',
      });
    },
  });

  const handleSubmit = e => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      let firstName = '';
      let lastName = '';
      let nameArr = formData.name.split(' ');

      formData.message = '';
      formData.success = false;

      if (nameArr.length > 1) {
        firstName = nameArr.shift();
        lastName = nameArr.join(' ');
      } else {
        setFormData({
          ...formData,
          success: false,
          message: 'Input your first and last name, separated by a space',
        });
        reject();
        return;
      }

      api
        .SIGN_UP({
          first_name: firstName,
          last_name: lastName,
          email: formData.email,
          password: formData.password,
          payload: cookies,
        })
        .then(response => {
          if (response == 201) {
            apiv2.GET_ME().then(response => {
              let { id, email } = response.data;

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'sign_up',
                user_id: id,
                email: email,
              });

              dispatch(setMe(response.data));
              navigate('/create-account', { replace: true });
            });
          } else {
            let message =
              'Something went wrong, please try again after sometime.';
            if (response?.email?.length > 0) {
              message = response.email.join('<br>');
            }
            if (response?.password?.password.length > 0) {
              message = response.password.password.join('<br>');
            }
            setFormData({ ...formData, success: false, message: message });
          }
        })
        .finally(() => {
          resolve();
        });
    });
  };

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleCheckbox = e =>
    setFormData({ ...formData, remember: e.target.checked });

  document.title = 'Airtomic: Sign Up';

  return (
    <main className={css.mainSignup}>
      <div className={`${css.auth_form_wrapper} auth-form-wrapper`}>
        <Modal
          type="signup"
          data={formData}
          onGoogleLogin={handleGoogleLogin}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onCheckbox={handleCheckbox}
        />
      </div>
    </main>
  );
};

export default SignUpForm;

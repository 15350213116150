import axios from 'axios';
import { error, failGetDataOnDashboard } from '../helpers/notyf';
import service from './service';

const URL = process.env.REACT_APP_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;

axios.defaults.withCredentials = true;

const LOG_IN = credantials =>
  service
    .post(`${URL}/auth/token/`, credantials)
    .then(response => {
      localStorage.setItem('ACCESS_TOKEN', response.data.access);
      localStorage.setItem('REFRESH_TOKEN', response.data.refresh);
      return response.status;
    })
    .catch(er => {
      let tokenCallFrom = localStorage.getItem('TOKEN_CALL_FROM');
      if (tokenCallFrom === 'LOGIN_PAGE') {
        return er.response.data;
      } else {
        error(er);
      }
    });

const SIGN_UP = params =>
  service
    .post(`${URL}/auth/signup/`, params)
    .then(response => {
      localStorage.setItem('ACCESS_TOKEN', response.data.access);
      localStorage.setItem('REFRESH_TOKEN', response.data.refresh);
      return response.status;
    })
    .catch(er => {
      return er.response.data;
    });

const GOOGLE_LOGIN = params =>
  service
    .post(`${URL}/auth/social-login/google/`, params)
    .then(response => {
      localStorage.setItem('ACCESS_TOKEN', response.data.access);
      localStorage.setItem('REFRESH_TOKEN', response.data.refresh);
      return response.status;
    })
    .catch(er => {
      return er.response.data;
    });

const RESET_PASSWORD = params =>
  service
    .post(`${URL}/auth/reset-password/`, params)
    .then(response => {
      return response.data;
    })
    .catch(er => {
      return er.response.data;
    });

const RESET_PASSWORD_CONFIRM = params =>
  service
    .post(`${URL}/auth/reset-password/confirm`, params)
    .then(response => {
      return response.data;
    })
    .catch(er => {
      return er.response.data;
    });

const GET_GOOGLE_LINK = (redirectUri) =>
  service
    .post(
      `${URL}/sources/google/url/`,
      {
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });

const GET_FACEBOOK_LINK = (redirectUri) =>
  service
    .post(
      `${URL}/sources/facebook/url/`,
      {
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });

const GET_TIKTOK_LINK = redirectUri =>
  service
    .post(
      `${URL}/sources/tiktok/url/`,
      {
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });
  
const GET_LINKED_IN_LINK = redirectUri =>
    service
      .post(
        `${URL}/sources/linkedin/url/`,
        {
          redirect_uri: redirectUri,
        },
        {
          headers: {
            Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        },
      )
      .then(response => response)
      .catch(er => {
        error(er);
      });

const SEND_GOOGLE_CODE = (code, redirectUri) =>
  service
    .post(
      `${URL}/sources/google/url/exchange/`,
      {
        code: code,
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });

const SEND_FACEBOOK_CODE = (code, redirectUri) =>
  service
    .post(
      `${URL}/sources/facebook/url/exchange/`,
      {
        code: code,
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });

const SEND_TIKTOK_CODE = (code, redirectUri) =>
  service
    .post(
      `${URL}/sources/tiktok/url/exchange/`,
      {
        code: code,
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response)
    .catch(er => {
      error(er);
    });
  
  const SEND_LINKED_IN_CODE = (code, redirectUri) =>
    service
      .post(
        `${URL}/sources/linkedin/url/exchange/`,
        {
          code: code,
          redirect_uri: redirectUri,
        },
        {
          headers: {
            Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
        },
      )
      .then(response => response)
      .catch(er => {
        error(er);
      });

const GET_GOOGLE_DATA_SOURCES = () =>
  service
    .get(`${URL}/sources/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_GOOGLE_DATA_SOURCES_ALL = () =>
  service
    .get(`${URL}/sources/accounts/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_FACEBOOK_DATA_SOURCES = () =>
  service
    .get(`${URL}/facebook/account/sources/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const DELETE_GOOGLE_DATA_SOURCE = id =>
  service
    .delete(`${URL}/sources/${id}/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_ALL_CLIENTS = () =>
  service
    .get(`${URL}/clients/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_ACCOUNTS = (id, params = {}) =>
  service
    .get(`${URL}/sources${id ? `/${id}` : ""}/accounts/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_CLIENT_DROPDOWNS = () =>
  service
    .get(`${URL}/clients/fields/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const CREATE_CLIENT = formData =>
  service
    .post(`${URL}/clients/`, formData, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response)
    .catch(er => {
      error(er);
    });

const UPDATE_CLIENT = (id, formData) =>
  service
    .patch(`${URL}/clients/${id}/`, formData, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response)
    .catch(er => {
      error(er);
    });

const GET_CAMPAIGNS = (account_id, params = {}) => {
  return service
    .get(`${URL}/sources${account_id ? `/accounts/${account_id}` : ""}/campaigns/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });
};

const CHECK_BUDGET_NAME = ({ budgetName, budgetId }) =>
  service
    .post(
      `${URL}/budgets/check/`,
      { name: budgetName, ...(budgetId && { exclude_id: budgetId }) },
      {
        headers: {
          Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )

const CHECK_CLIENT_NAME = ({ clientName, clientId }) =>
  service.post(
    `${URL}/clients/name/`,
    { name: clientName, ...(clientId && { exclude_id: clientId }) },
    {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    },
  );


const CREATE_BUDGET = formData =>
  service
    .post(`${URL}/budgets/`, formData, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response)
    .catch(er => {
      error(er);
    });

const UPDATE_BUDGET = (formData, id) =>
  service
    .patch(`${URL}/budgets/${id}/`, formData, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response)
    .catch(er => {
      error(er);
    });

const GET_CLIENT_BUDGET_PACING = params =>
  service
    .get(`${URL}/dashboard/clients/budget/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      failGetDataOnDashboard();
    });

const GET_CLIENT_PERFORMANCE = params =>
  service
    .get(`${URL}/dashboard/clients/performance/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      failGetDataOnDashboard();
    });

const GET_BUDGET_BUDGET_PACING = params =>
  service
    .get(`${URL}/dashboard/budgets/pacing/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      failGetDataOnDashboard();
    });

const GET_BUDGET_PERFORMANCE = params =>
  service
    .get(`${URL}/dashboard/budgets/performance/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      failGetDataOnDashboard();
    });

const GET_CAMPAIGNS_PERFORMANCE = params =>
  service
    .get(`${URL}/dashboard/campaings/performance/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      params,
    })
    .then(response => response.data)
    .catch(er => {
      failGetDataOnDashboard();
    });

const LOG_OUT = () =>
  service
    .delete(`${URL}/auth/token/delete/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data);

const DELETE_CLIENT = id =>
  service
    .delete(`${URL}/clients/${id}/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const DELETE_CLIENTS = ids =>
  service
    .delete(`${URL}/clients/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      data: {
        ids: ids,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const DELETE_BUDGET = id =>
  service
    .delete(`${URL}/budgets/${id}/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const DELETE_BUDGETS = ids =>
  service
    .delete(`${URL}/budgets/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      data: {
        ids: ids,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_CLIENT = id =>
  service
    .get(`${URL}/clients/${id}/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_BUDGET = id =>
  service
    .get(`${URL}/budgets/${id}/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_GRAPH = params =>
  service
    .get(`${URL}/dashboard/`, {
      params: { ...params },
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_METRICS_OF_CLIENT_CUSTOM_VIEW = (id, params) =>
  service
    .get(`${URL}/dashboard/clients/custom-views/${id}/`, {
      params: { ...params },
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });

const GET_METRICS_OF_BUDGET_CUSTOM_VIEW = (id, params) =>
  service
    .get(`${URL}/dashboard/budgets/custom-views/${id}/`, {
      params: { ...params },
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er);
    });



const GET_ALL_BUDGETS = () =>
  service
    .get(`${URL}/budget/list/`, {
      headers: {
        Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => console.log(response.data.data))
    .catch(er => {
      error(er);
    });

const GET_COMPANY = () =>
  service.get(`${URL}/companies/`, {
    headers: {
      Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
    },
  });

const CREATE_COMPANY = params =>
  service
    .post(`${URL}/companies/`, params)
    .then(response => {
      return response;
    })
    .catch(er => {
      return er.response;
    });

const EDIT_COMPANY_NAME = formData =>
  service.patch(`${URL}/companies/`, formData);

const DELETE_COMPANY = () =>
  service.delete(`${URL}/companies/`, {
    headers: {
      Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
    },
  });

const GET_CURRENT_LOCATION = () =>
  axios
    .get(`https://ipapi.co/json/`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    .then(response => response);
  
const GET_BUDGETS_OF_CLIENT = id =>
  axios.get(`${URL}/clients/${id}/budgets/`, {
    headers: {
      Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
    },
  });

const api = {
  URL,
  LOG_IN,
  SIGN_UP,
  GOOGLE_LOGIN,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  GET_GOOGLE_LINK,
  GET_FACEBOOK_LINK,
  GET_TIKTOK_LINK,
  GET_LINKED_IN_LINK,
  SEND_GOOGLE_CODE,
  SEND_FACEBOOK_CODE,
  SEND_TIKTOK_CODE,
  SEND_LINKED_IN_CODE,
  GET_GOOGLE_DATA_SOURCES,
  GET_FACEBOOK_DATA_SOURCES,
  DELETE_GOOGLE_DATA_SOURCE,
  GET_ALL_CLIENTS,
  GET_ACCOUNTS,
  GET_CLIENT_DROPDOWNS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  GET_CAMPAIGNS,
  CHECK_BUDGET_NAME,
  CHECK_CLIENT_NAME,
  CREATE_BUDGET,
  UPDATE_BUDGET,
  GET_CLIENT_BUDGET_PACING,
  GET_CLIENT_PERFORMANCE,
  GET_BUDGET_BUDGET_PACING,
  GET_CAMPAIGNS_PERFORMANCE,
  LOG_OUT,
  GET_GOOGLE_DATA_SOURCES_ALL,
  DELETE_CLIENT,
  DELETE_CLIENTS,
  DELETE_BUDGET,
  DELETE_BUDGETS,
  GET_CLIENT,
  GET_BUDGET,
  GET_GRAPH,
  GET_ALL_BUDGETS,
  GET_BUDGET_PERFORMANCE,
  GET_CURRENT_LOCATION,
  GET_COMPANY,
  CREATE_COMPANY,
  EDIT_COMPANY_NAME,
  DELETE_COMPANY,
  GET_BUDGETS_OF_CLIENT,
  GET_METRICS_OF_CLIENT_CUSTOM_VIEW,
  GET_METRICS_OF_BUDGET_CUSTOM_VIEW
};

export default api;

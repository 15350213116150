import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo2.png';
import Button from '../reusable/Button/Button';

import { useDispatch, useSelector } from 'react-redux';
import addUser from '../../assets/addUser2.svg';
import addUserActive from '../../assets/addUserActive2.svg';
import arrowUp from '../../assets/arrowUp.svg';
import budget from '../../assets/budget2.svg';
import budgetActive from '../../assets/budgetActive2.svg';
import cloud from '../../assets/cloud2.svg';
import cloudActive from '../../assets/cloudActive2.svg';
import dashboard from '../../assets/dashboard2.svg';
import dashboardActive from '../../assets/dashboardActive2.svg';
import settings from '../../assets/settings2.svg';
import settingsActive from '../../assets/settingsActive2.svg';
import signOut from '../../assets/signOut.svg';
import team from '../../assets/team2.svg';
import teamActive from '../../assets/teamActive2.svg';
import WarningIcon from '../../assets/warning-icon.svg';
import api from '../../config/api';
import { STATUS } from '../../contants/datasource';
import { PERMISSIONS } from '../../contants/permissions';
import { setRuleManagerState } from '../../features/userSettings/userSettingsSlice';
import { checkIfPaymentIsValid, isReadOnlyOrPaymentValid } from '../../utils';
import css from './Sidebar.module.css';

const sources = ['google'];

const Sidebar = () => {
  const [isSettings, setIsSettings] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const currUser = useSelector(state => state.me);
  const { dataSource } = useSelector(state => state.dataSource);

  const dispatch = useDispatch();

  const isWarningTabDataSource = dataSource?.find(
    dataSource => dataSource.status !== STATUS.ACTIVE,
  );
  const { notifications } = currUser;
  const permissionOfCurrUser = currUser.permission_level?.name;
  const isReadOnly = permissionOfCurrUser === PERMISSIONS.READ_ONLY;
  const isNotAdmin =
    permissionOfCurrUser === PERMISSIONS.READ_ONLY ||
    permissionOfCurrUser === PERMISSIONS.STANDARD;

  const hasSubscriptionIssue = !checkIfPaymentIsValid(notifications);
  const hasSubscriptionOrReadOnlyProblem = !isReadOnlyOrPaymentValid(
    notifications,
    isReadOnly,
  );

  useEffect(() => {
    location.pathname.includes('/settings')
      ? setIsSettings(true)
      : setIsSettings(false);
  }, [location.pathname]);

  const handleSignOut = () => {
    api
      .LOG_OUT()
      .then(res => {
        if (res.status === 'OK') {
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN');
          navigate('/login', { replace: true });
        }
      })
      .catch(() => {
        navigate('/login', { replace: true });
      });
  };

  const handleSettings = () => setIsSettings(!isSettings);

  const handleClickAutomatedRules = () => {
    const { pathname } = location;
    if (pathname === '/settings/automated-rules')
      dispatch(setRuleManagerState(false));
  };

  const hasSubscriptionIssueClass = ({ isActive }) =>
    hasSubscriptionIssue ? css.disable : isActive ? css.active : css.navLink;

  const hasSubscriptionOrReadOnlyProblemClass = ({ isActive }) =>
    hasSubscriptionOrReadOnlyProblem
      ? css.disable
      : isActive
      ? css.active
      : css.navLink;

  return (
    <aside className={css.aside}>
      <div>
        <img className={css.logo} alt="Logo" src={logo} />
        <nav className={css.nav}>
          <NavLink
            className={hasSubscriptionIssueClass}
            to={hasSubscriptionIssue ? '#' : '/dashboard'}
          >
            <div className={css.imgContainer}>
              <img
                alt="dashboard"
                src={
                  location.pathname.includes('/dashboard')
                    ? dashboardActive
                    : dashboard
                }
              />
            </div>
            Dashboard
          </NavLink>
          <NavLink
            disabled
            className={hasSubscriptionOrReadOnlyProblemClass}
            to={hasSubscriptionOrReadOnlyProblem ? '#' : '/create-client'}
          >
            <div className={css.imgContainer}>
              <img
                alt="create client"
                src={
                  location.pathname.includes('/create-client')
                    ? addUserActive
                    : addUser
                }
              />
            </div>
            Create Client
          </NavLink>
          <NavLink
            className={props =>
              hasSubscriptionOrReadOnlyProblemClass({
                isActive:
                  props.isActive ||
                  location.pathname.includes('/create-budget'),
              })
            }
            to={
              hasSubscriptionOrReadOnlyProblem ? '#' : '/create-budget/client'
            }
          >
            <div className={css.imgContainer}>
              <img
                alt="create budget"
                src={
                  location.pathname.includes('/create-budget')
                    ? budgetActive
                    : budget
                }
              />
            </div>
            Create Budget
          </NavLink>
          <NavLink
            className={hasSubscriptionOrReadOnlyProblemClass}
            to={hasSubscriptionOrReadOnlyProblem ? '#' : '/data-sources'}
          >
            <div className={css.imgContainer}>
              <img
                alt="data sources"
                src={
                  location.pathname.includes('/data-sources')
                    ? cloudActive
                    : cloud
                }
              />
            </div>
            Data Sources
            {location.pathname !== '/data-sources' &&
              isWarningTabDataSource && (
                <img
                  className={css.warningTabDataSource}
                  src={WarningIcon}
                  alt=""
                />
              )}
          </NavLink>
          <NavLink
            className={hasSubscriptionOrReadOnlyProblemClass}
            to={hasSubscriptionOrReadOnlyProblem ? '#' : '/team'}
          >
            <div className={css.imgContainer}>
              <img
                alt="team"
                src={location.pathname.includes('/team') ? teamActive : team}
              />
            </div>
            Team
          </NavLink>
          <div
            className={
              location.pathname.includes('/settings') || isSettings
                ? css.active
                : css.navLink
            }
            onClick={handleSettings}
          >
            <div className={css.imgContainer}>
              <img
                alt="settings"
                src={
                  location.pathname.includes('/settings') || isSettings
                    ? settingsActive
                    : settings
                }
              />
            </div>
            Settings
            {isSettings && (
              <div className={`${css.imgContainer} ${css.right}`}>
                <img alt="arrow up" src={arrowUp} />
              </div>
            )}
          </div>
          {isSettings && (
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isReadOnly
                      ? css.disableSetting
                      : isActive
                      ? css.activeSetting
                      : css.navLinkSetting
                  }
                  to={isReadOnly ? '#' : '/settings/automated-rules'}
                  onClick={handleClickAutomatedRules}
                >
                  Automated Rules
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? css.activeSetting : css.navLinkSetting
                  }
                  to="/settings/user"
                >
                  User Settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isNotAdmin
                      ? css.disableSetting
                      : isActive
                      ? css.activeSetting
                      : css.navLinkSetting
                  }
                  to={isNotAdmin ? '#' : '/settings/account'}
                >
                  Account Settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isNotAdmin
                      ? css.disableSetting
                      : isActive
                      ? css.activeSetting
                      : css.navLinkSetting
                  }
                  to={isNotAdmin ? '#' : '/settings/usage-billing'}
                >
                  Usage & Billing
                </NavLink>
              </li>
            </ul>
          )}
        </nav>
      </div>
      <Button styled="signOut" onClick={handleSignOut}>
        <div className={css.signOutWrapper}>
          <img className={css.signOutImg} alt="sign out" src={signOut} />
          <span className={css.signOutText}>Sign out</span>
        </div>
      </Button>
    </aside>
  );
};

export default Sidebar;

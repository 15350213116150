import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiv2 from '../../config/apiv2';
import api from '../../config/api';
import { successLogin, failLogin } from '../../helpers/notyf';
import Modal from '../../components/reusable/Modals/Modal';
import logo from '../../assets/logo.svg';
import css from './LoginPage.module.css';

const ConfirmInvitationPage = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    password: '',
  });

  const location = useLocation();

  const navigate = useNavigate();

  const handleGoogleLogin = () => {};

  const handleSubmit = e => {
    e.preventDefault();
    const token = location.pathname.split('/')[2];

    apiv2
      .CONFIRM_INVITATION(formData, token)
      .then(res => {
        const {
          data: { email },
        } = res;

        api
          .LOG_IN({ email, password: formData.password })
          .then(response =>
            response === 200
              ? successLogin() && navigate('/dashboard', { replace: true })
              : failLogin(),
          );
      })
      .catch(e => {
        const errors = e?.response?.data;
        const parsed = Object.entries(errors);

        let fieldsError = {
          first_name: '',
          last_name: '',
          password: '',
          other: '',
        };

        parsed.forEach(e => {
          const [field, messages] = e;
          if (
            field === 'first_name' ||
            field === 'last_name' ||
            field === 'password'
          )
            fieldsError[field] = Array.isArray(messages)
              ? messages.join('\n')
              : messages[field].join('\n');
          else failLogin();
        });

        setErrors(fieldsError);
      });
  };

  const handleChange = e => {
    setErrors(p => ({ ...p, [e.target.name]: '' }));

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <main>
      <img className={css.logo} alt="Logo" src={logo} />
      <Modal
        type="confirm-invitation"
        data={formData}
        onGoogleLogin={handleGoogleLogin}
        onSubmit={handleSubmit}
        onChange={handleChange}
        errors={errors}
      />
    </main>
  );
};

export default ConfirmInvitationPage;
